<template>
<div class="global-width">
    <div class="conceal_text">
        <h1>隐私政策</h1>
艾数软件（深圳）有限公司（简称“我们”）作为首席软件官的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定本《首席软件官隐私政策》（“本隐私政策”）并特别提示：希望您在使用首席软件官及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。<br>
适用范围：本隐私政策适用于您通过首席软件官App、首席软件官微信公众号以及首席软件官微信小程序、首席软件官网站、供第三方网站和应用程序使用的首席软件官开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。<br>
本隐私政策将帮助您了解：<br>
1.我们如何收集和使用个人信息；<br>
2.我们如何使用Cookie等同类技术<br>
3.我们如何共享、转让、公开披露个人信息<br>
4.我们如何存储个人信息<br>
5.我们如何保护个人信息的安全<br>
6.管理您的个人信息<br>
7.第三方链接<br>
8.儿童个人信息的保护<br>
9.隐私政策的修订和通知<br>
10.联系我们<br>
<h2>一、我们如何收集和使用个人信息</h2>
我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的个人信息。个人信息是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。<br>
<h3>1.1注册、登录、认证</h3>
<h4>1.1.1注册、登录帐号</h4>
当您注册、登录首席软件官及相关服务时，您需要向我们提供您的手机号、密码，以便于我们为您创建帐号，并帮助您完成注册。您还可以根据自身需求选择上传头像、填写组织、职业、性别、生日、教育经历（学校名称、专业和学历、核心课程以及起止时间）、工作履历（组织名称、期间最高职位、描述以及起止时间）以及绑定邮箱来完善您的账号信息。<br>
您也可以使用第三方帐号（如微信、QQ）登录并使用首席软件官，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与首席软件官帐号绑定，使您可以直接登录并使用本产品和相关服务。如果您使用微信登录或将您的账号与微信绑定，我们还将收集您的微信OpenID。在使用第三方账号进行登录时，可能需要将实现登录所必需的信息在剪切板中写入与读取。这些信息仅供实现登录相关的目的所使用。<br>
<h4>1.1.2认证</h4>
在您使用身份认证的功能或相关服务所需时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码等）以完成实名验证。部分信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
<h3>1.2创建、加入、管理组织</h3>
<h4>1.2.1创建、加入组织</h4>
当您创建组织的时候，您需要向我们提供您的组织名称、您的职位、管理员邮箱以及预计人数。您还可以选择向我们提供您的组织logo、所在地、所在行业、二级域名以及工作地点，以完善您的组织信息。当您选择加入组织时，您需要向我们提供您想要加入的组织ID。
<h4>1.2.2添加组织成员</h4>
当您添加组织成员时，您需要向我们提供成员姓名和手机号码，您也可以向我们提供新增成员的添加方式、所属部门、职位、工作地点、工号以及工作电话，以完善其个人信息。如果您通过邀请方式添加成员，我们还会收集被邀请人的手机号或邮箱。
<h4>1.2.3创建部门</h4>
当您创建部门时，您需要向我们提供您所创建的部门名称以及上级部门名称。
<h4>1.2.4汇报关系</h4>
当您使用汇报关系功能时，您可以通过添加下属的方式逐级别设置汇报对象，对此我们将收集您所在组织的汇报关系。
<h4>1.2.5创建群组</h4>
如果您创建群组，您需要向我们提供您的群组类型、群组名称，您还可以选择新成员加入是否需要管理员验证或填写群公告。
<h4>1.2.6通讯录隔离</h4>
如果您使用通讯录隔离功能，您需要向我们提供您的通讯录隔离规则，即设置仅允许查看本部门通讯录的权限所对应的部门或个人以及设置额外可见的成员所对应的部门和个人。
<h4>1.2.7发票设置</h4>
如果您选择设置发票，您需要向我们提供您所在组织的税务登记号、邮寄地址、发票接收人、邮编、电话、收集、邮箱地址、邮箱接收人、传真以及发票类型（普票、专票）。
<h4>1.2.8权限管理</h4>
当您管理权限时，您需要选择企业应用管理员、企业账户管理员以及超级管理员所对应的成员，以便于实现权限管理。此外，我们还会收集您的权限管理日志信息（操作人姓名、操作时间、角色权限以及对应的成员姓名）。
<h3>1.3创建、管理应用</h3>
<h4>1.3.1创建应用</h4>
当您创建应用时，您需要向我们提供工作表名称、表单设计信息（控件名称、控件描述以及附件），以便于我们帮助您创建应用。
<h4>1.3.2创建记录</h4>
当您在应用中创建记录时，您需要向我们提交记录名称、描述以及您想要上传的文件，以便于我们帮助您创建记录仪。
<h4>1.3.3管理应用</h4>
当您在应用中配置应用的角色、成员和权限时，我们会收集应用管理员的姓名、应用成员、对该应用拥有只读权限的成员以及其他您想要添加的角色名称以及对应的描述、分发的应用项以及权限。
<h3>1.4动态发布与互动</h3>
<h4>1.4.1信息发布</h4>
a.您发布信息、音视频、图片等内容或进行评论时，我们将收集您发布的信息，并展示您的昵称、头像、发布内容和信息。
b.您使用发布图片、音视频功能时，我们会请求您授权相机、照片、麦克风权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用首席软件官的其他功能。
<h4>1.4.2互动交流</h4>
a.当您关注帐号、进行浏览、评论、关注（收藏）、喜欢（点赞）或分享时，我们会收集您关注的帐号和前述信息，并向您展示关注帐号发布内容。<br>
b.您可以在首席软件官中添加或删除朋友，为帮您实现这一功能，我们会创建朋友列表信息。朋友列表是建立与管理朋友关系所必需的必要信息。<br>
c.当您使用群功能时，我们会创建群聊列表并收集群组内通讯信息。<br>
d.当您与首席软件官好友进行互动交流时，为实现功能所需，首席软件官会收集通讯信息。<br>
e.当您选择使用扫码添加朋友时，首席软件官可能会向您请求摄像头权限，摄像头权限是敏感权限，拒绝提供仅会使您无法通过扫码途径添加朋友，不影响其他功能的使用。
<h3>1.5创建任务和日程、上传文件</h3>
<h4>1.5.1创建任务</h4>
当您创建任务时，您需要向我们提供您的任务归属、项目名称、任务名称、任务负责人、任务参与者、设置时间、描述和附件，以便于我们为您创建任务。
<h4>1.5.2创建日程</h4>
当您创建日程时，您需要向我们提供您的日程名称、起止时间、提醒时间、提醒方式、成员名称、地点、描述和附件，是否重复（重复的类型、频率、结束日期）以及是否为私密日程。
<h4>1.5.3上传文件</h4>
当您上传文件时，我们会收集您向我们上传的信息，以便于帮助您实现上传并管理文件的目的。
<h3>1.6消息通知</h3>
您知悉并同意，对于您在使用产品及/或服务的过程中提供的您的联系方式（联系电话和邮箱），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证、向您介绍我们的产品和活动等用途。您可以通过我们向您发送的短信或邮件中所提示的方式拒绝接收我们向您发送的营销信息，或通过“联系我们”部分所列方式与我们联络。为了保障您可以正常接收推送内容，避免消息遗漏，我们使用了App自启动和关联启动其他App功能。
<h3>1.7搜索功能</h3>
当您使用搜索功能时，我们会收集您的搜索记录，以便更好地向您展示和您的搜索内容匹配的搜索记录。
<h3>1.8打卡功能</h3>
当您使用我们的打卡功能，我们可能会收集您的地理位置和设备识别信息。
<h3>1.9客户服务</h3>
当您使用我们的客户服务时，为了更好地帮您解决问题，我们将会收集您与客户的沟通记录。如果您选择通过留言联系我们，我们会收集您的称呼、邮箱地址、留言信息以及相应的附件，以便于帮助您解决问题。
<h3>1.10运营与安全运行</h3>
<h4>1.10.1运营与安全保障</h4>
我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。出于向您正常提供服务的目的，维护产品和服务的稳定运营，我们将收集您在产品使用过程中产生的使用日志。
<h4>1.10.2设备信息与日志信息</h4>
a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、 IP 地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。<br>
b.为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。<br>
c.我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。
<h3>1.11统计分析</h3>
为了分析应用新增、激活、留存、性能等统计性指标，我们可能会调用剪切板对相关统计信息进行归因分析，请您放心，对此我们不会收集您的隐私信息。
<h3>1.12收集、使用个人信息目的变更</h3>
请您了解，随着我们业务的发展，可能会对首席软件官的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。
<h3>1.13源自第三方电子商务平台的买家个人隐私数据声明</h3>
请您了解，我们从第三方电子商务平台（包含但不限于亚马逊、沃尔玛、Etsy）收集的个人买家隐私数据，会被严格加密保护，并且限制使用特定的场景。具体来说，我们只会在分配物流、打印面单和税务开票几个特定场景下使用这些数据，而且除了您的管理员以外，任何人都不能查看或访问这些数据。如果您的非管理员人员需要使用这些数据进行经营活动，必须获得管理员特别授权，并且单次授权的时间默认不得超过一个小时。我们这样做的目的是为了帮助您遵守全球各国家的隐私法律法规，确保您的经营活动符合相关法规要求，同时保护买家个人隐私不受侵犯。我们将采取严格的安全措施，以确保各方的个人信息得到充分的保护，绝不会滥用或泄露任何个人隐私信息。<br>
特别提示：如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。

<h2>二、我们如何使用Cookie等同类技术</h2>
Cookie 和设备信息标识等同类技术是互联网中普遍使用的技术。当您使用首席软件官及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie 或匿名标识符，以收集、标识您访问、使用本产品时的信息。我们承诺，不会将Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie 和同类技术主要为了实现以下功能或服务：
<h3>2.1保障产品与服务的安全、高效运转</h3>
我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。
<h3>2.2帮助您获得更轻松的访问体验</h3>
使用此类技术可以帮助您省去重复您填写个人信息、输入搜索内容的步骤和流程（示例：记录搜索、表单填写）。
<h3>2.3Cookie的清除</h3>
大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，您可能无法使用由我们提供的、依赖于Cookie的服务或相应功能。
<h2>三、我们如何共享、转让、公开披露个人信息</h2>
<h3>3.1共享</h3>
<h4>3.1.1共享原则</h4>
a.授权同意原则： 未经您的同意，我们不会共享您的个人信息。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。<br>
b.合法正当与最小必要原则：共享的数据必须具有合法正当目的，且共享的数据以达成必要目的为限。<br>
c.安全审慎原则： 我们将审慎评估第三方使用共享信息的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作法律协议。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。<br>
d.例外：我们可能根据法律法规、法院命令或其他法律程序、或有权政府机关的要求，向法院或政府机关提供您的信息。
<h4>3.1.2实现功能或服务的共享信息（SDK清单）</h4>
<table class="table_1">
    <tr><th>SDK名称</th><th width="500px">目的</th><th width="120px">收集个人信息类型</th><th width="300px">具体个人信息</th><th width="120px">SDK 提供方</th><th width="200px">隐私政策链接</th></tr>
    <tr><td>高德地图</td><td>当您使用地理位置相关服务时，我们会通过SDK或相关技术将GPS信息与位置服务提供商（高德地图）进行共享以便可以向您返回位置结果。GPS信息是个人敏感信息，拒绝提供，仅会影响地理位置服务功能，但不影响其他功能的正常使用。</td><td>地理位置信息</td><td>地理位置信息</td><td>高德</td><td>https://lbs.amap.com/pages/privacy/</td></tr>
    <tr><td>华为推送</td><td>推送消息</td><td>设备信息</td><td>手机型号、版本、IMEI/MAC/Android ID/OPENUDID/GUID/SIM卡IMSI/粗略地理位置信息</td><td>华为</td><td>https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides-V5/privacy-statement-0000001050042021-V5</td></tr>
    <tr><td>小米推送</td><td>推送消息</td><td>设备信息</td><td>手机型号、版本、IMEI/MAC/Android ID/OPENUDID/GUID/SIM卡IMSI/粗略地理位置信息</td><td>小米</td><td>https://dev.mi.com/console/doc/detail?pId=1822</td></tr>
    <tr><td>VIVO推送</td><td>推送消息</td><td>设备信息</td><td>手机型号、版本、IMEI/MAC/Android ID/OPENUDID/GUID/SIM卡IMSI/粗略地理位置信息</td><td>VIVO</td><td>https://dev.vivo.com.cn/documentCenter/doc/366</td></tr>
    <tr><td>GoogleFCM</td><td>推送消息</td><td>设备信息</td><td>手机型号、版本、IMEI/MAC/Android ID/OPENUDID/GUID/SIM卡IMSI/粗略地理位置信息</td><td>Google</td><td>https://firebase.google.com/support/privacy?hl=zh-cn</td></tr>
    <tr><td>友盟SDK</td><td>数据统计分析</td><td>使用状况和设备信息</td><td>使用的城市分布，运营商网络状况，App崩溃状态、IMEI/MAC/Android ID/OPENUDID/GUID/SIM卡IMSI/粗略地理位置信息。</td><td>友盟</td><td>https://www.umeng.com/page/policy</td></tr>
    <tr><td>Bugly</td><td>用于分析App崩溃信息，来快速定位BUG尽可能加快修复问题。</td><td>设备信息</td><td>IMEI/MAC/Android ID</td><td>腾讯</td><td>https://static.bugly.qq.com/bugly-sdk-privacy-statement.pdf</td></tr>
</table>
<h3>3.2委托处理</h3>
a.为了实现本隐私政策所述的目的，我们可能会委托第三方服务提供商帮助我们提供相关的运营和服务的支持，在此过程之中，会处理您的个人信息，以实现我们产品与/或服务的功能。<br>
b.第三方服务提供商包括：软件、系统技术服务提供商；数据分析公司；在线客户服务插件提供商；网站/系统服务器托管公司。
<h3>3.3转让</h3>
c.我们不会转让您的个人信息给任何其他第三方，除非征得您的明确同意。<br>
d.随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。
<h3>3.4公开披露</h3>
a.我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施。<br>
b.对违规帐号、欺诈行为进行处罚公告时，我们会披露相关帐号的信息。<br>
c.您公开发布的动态将会在个人主页中展示。<br>
d.我们还会根据您的要求，在您单独同意的披露方式下披露您指定的个人信息。

<h3>3.5 源自第三方电子商务平台买家个人隐私数据特别声明</h3>
请您注意，我们从第三方电子商务平台（包含但不限于亚马逊、沃尔玛、Etsy）收集的个人买家隐私数据，您将完全被禁止共享、转让、公开披露和处理这些数据的权力，仅限于我们提供特定的场景特定的期限进行特定的使用。同时我们具备有对这类数据定期删除的完全处理权。

<h2>四、我们如何存储个人信息</h2>
<h3>4.1存储地点</h3>
我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
<h3>4.2存储期限</h3>
我们仅在为提供首席软件官及服务之目的所必需的期间内保留您的个人信息：您发布的信息、评论、喜欢（点赞）及相关信息，在您未撤回、删除或未注销帐号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
<h3>4.3 源自第三方电子商务平台买家个人隐私数据存储地点及期限特别声明</h3>
请您注意，我们会在符合全球各国家隐私法律法规要求的前提下，收集和存储买家的个人隐私数据。为了确保数据的安全和保密，我们会将这些数据存储在全球所在国家的亚马逊服务器上，并且采取各种安全措施来保护这些数据不被泄露或滥用。<br>
我们会尽最大努力确保买家的个人隐私数据得到妥善处理和管理。对于买家的个人隐私数据，我们默认的最高存储期限是30天，之后系统会自动删除全部信息。在此期限内，我们将根据各第三方电子商务平台及各国家的隐私政策决定使用这些数据，目前仅限于分配物流、打印面单和税务开票等场景下使用。<br>
需要注意的是，我们只会在遵守相关法律法规的前提下收集和使用这些数据，而且除了您的管理员以外，任何人都不能查看或访问这些数据。如果您的非管理员需要使用这些数据进行经营活动，必须获得管理员特别授权，并且单次授权的时间默认不得超过一个小时，此行为是为了避免买家个人隐私数据被人恶意采集使用。

<h2>五、我们如何保护个人信息的安全</h2>
a.我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。<br>
b.我们会使用不低于行业通行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。<br>
c.我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。<br>
d.尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。<br>
e.您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人等。<br>
f.我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。<br>
g.您一旦离开首席软件官及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在首席软件官及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于首席软件官的链接或引导。
<h2>六、管理您的个人信息</h2>
我们非常重视您对个人信息的管理，我们不会搜集您的使用习惯，浏览记录，搜索记录等使用信息，不会向您推送任何与您无关的广告信息，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、注销帐号、投诉举报以及设置隐私功能的相关权利，以使您有能力保障您的隐私和信息安全。
<h3>6.1访问、删除、更正您的个人信息</h3>
<h4>6.1.1查询、访问、更正个人帐号信息</h4>
您可以查询、访问您的头像、用户名、简介、性别、生日、地区，您可以在首席软件官网页【我】-【个人信息】或首席软件官App【我】-【个人资料】进行查询、访问、更正。
<h4>6.1.2控制信息可见范围</h4>
a.进入首席软件官网页【我】-【账户隐私】-【手机号仅自己可见】或首席软件官App【我】-【隐私设置】-【手机号仅自己可见】中控制他人是否在您的个人主页中看到自己的手机信息；<br>
b.进入首席软件官网页【我】-【账户隐私】-【邮箱仅自己可见】或首席软件官App【我】-【隐私设置】-【邮箱仅自己可见】中控制他人是否在您的个人主页中看到自己的邮箱信息。
<h3>6.2复制权</h3>
您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您可以随时通过“联系我们”部分所列的方式联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。
<h3>6.3删除权</h3>
您有权在如下情形要求我们删除您的相关信息，除非法律法规另有要求：（a）如果我们在收集或使用个人信息时违反了法律法规的规定；（b）我们没有合法基础收集您的个人信息的，或您撤回同意时；（c）违反与您的约定收集、使用您的个人信息；（d）您注销账户；（e）我们不再向您提供产品或服务。您可根据以下“联系我们”部分所列的方式与我们联络，向我们提交相关要求。
<h3>6.4限制或拒绝处理的权利</h3>
您有权限制或者拒绝他人对您个人信息进行处理，我们将按照适用法律的规定为您提供限制或拒绝的途径，请根据以下“联系我们”部分所列的方式与我们联络。
<h3>6.5拒绝订阅营销消息</h3>
您有权拒绝接收我们向您发送的营销信息，您可以通过我们向您发送的短信或邮件中所提示的方式退订前述信息，或通过“联系我们”部分所列方式与我们联络。
<h3>6.6撤销同意权</h3>
如果您希望撤回对您个人信息处理的同意，您可以根据以下“联系我们”部分所列的方式与我们联络，向我们提交相关要求。
<h3>6.7注销帐号</h3>
你可以通过邮箱联系首席软件官客服或“联系我们”部分所述方式，申请账号注销。您知悉并理解，注销帐号的行为是不可逆的行为，当您注销帐号后，我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。
<h3>6.8投诉举报</h3>
您可以按照我们公示的制度进行投诉或举报。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过邮箱与我们联系反馈。我们核查后会在15个工作日内反馈您的投诉与举报。
<h3>6.9访问隐私政策</h3>
a.您可以在登录页面查看本隐私政策的全部内容。<br>
b.请您了解，本隐私政策中所述的首席软件官及相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的首席软件官软件及相关服务为准。<br>
c.您可以通过“联系我们”所列方式要求我们对隐私政策内容作出解释。
<h3>6.10停止运营向您告知</h3>
如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
<h2>七、第三方链接</h2>
首席软件官可能包含或链接至第三方提供的服务。该服务由第三方提供或运营，您使用第三方的服务以及向第三方提供的信息，将受该等第三方的用户协议和隐私条款约束，而不受本隐私政策的约束。我们对于我们链接到的其他网站或应用的内容或安全性不承担法律责任。
<h2>八、儿童个人信息的保护</h2>
一般来说，未经监护人的同意，我们不会主动收集和处理14岁以下儿童的个人信息。但是，由于技术限制，在某些情况下，我们提供在线产品和服务时无法识别用户的年龄。在这些情况下，根据相关法律，我们将用户视为有完全合法地权利向我们提供个人信息。如果我们无意中未经其监护人同意收集了任何儿童的个人信息，我们将在发现这种情况时或在监护人要求时及时删除。
<h2>九、隐私政策的修订和通知</h2>
a.为了给您提供更好的服务，首席软件官及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。<br>
b.本隐私政策更新后，我们会在首席软件官发出更新版本，并在更新后的条款生效前通过官方网站 https://www.sxrjg.com 公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。<br>
c.如果本隐私政策有较大变更，我们也将提供更明显的通知，如当您登录我们的应用时使用弹出窗口，或直接向您发送电子邮件等。本隐私政策的较大变更可能包括对处理个人信息的目的、处理的个人信息的类型以及使用个人信息的方式的重大更改，以及您对个人信息的权利或行使权利的方式的重大更改。
<h2>十、联系我们</h2>
首席软件官服务的提供者为：艾数软件（深圳）有限公司；<br>
其注册地址为： 深圳市宝安区新安街道兴东社区群辉路3号优创空间2号楼425；<br>
如果您对个人信息保护问题有投诉、建议、疑问；<br>
您可以将问题发至：info@sxrjg.com ；<br>
我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内回复；
        <!-- <p>{{ concealText }}</p> -->
        <!-- <br>
        <h2>常见问题</h2>
        <div class="qa">
            <div class="qaitem">
                <div class="title">问题一：</div>
                <div class="q">Describe the application or feature(s) you intend to build using the functionality in the requested roles.<br>请描述您打算使用所请求的角色功能来构建的应用程序或功能。</div>
                <div class="a">我们的应用程序是为亚马逊卖家客户设计的全自动化和半自动化解决方案，以满足他们特定的流程需求和增长目标。我们专注于提供定制化的流程、订单、产品、库存、销售和财务数据集成等解决方案，帮助卖家客户更快速、更轻松地实现其业务目标，无需长时间学习复杂的软件操作。<br>
我们的应用程序租用了亚马逊云的全球服务器，涉及到买家个人信息的处理也是高度安全和隐私保护的。我们将这些个人信息存储在所在国家当地的服务器上，只有符合特定使用场景的程序才能进行调用，而且我们会定期删除这些信息，以遵守全球各国的隐私法律。因此，我们的应用程序能够帮助卖家客户安全合规地处理买家个人信息，并减少潜在的法律风险。<br>
同时，我们的数据分析团队将帮助卖家客户掌握他们的业务情况，提供详细的销售报告，包括销售额、订单数量和退货率等指标，帮助卖家了解他们的业务状况。我们还可以进行竞争分析，以便他们可以更好地制定自己的销售策略。通过使用我们的应用程序和数据分析服务，卖家可以更好地了解市场需求和趋势，并制定相应的销售策略。这将帮助他们在竞争激烈的市场中获得更好的成长，并实现更好的业绩。我们的应用程序也支持FBM和FBA模式下的产品交付，以满足卖家的不同需求。<br>
与其他市场上提供的通用版管理软件服务相比，我们的低代码技术提供更大的灵活性，以满足卖家的个性化需求，并允许快速定制供应链系统流程。此外，我们的维度分析功能可以帮助卖家深入了解其产品和市场表现，从而制定更有效的业务战略。我们专注于帮助卖家客户提高业务效率、降低成本，并获得更好的成长机会。<br>
<br>
Our application is a fully automated and semi-automated solution designed for Amazon seller clients to meet their specific process requirements and growth goals. We specialize in providing customized solutions for processes, orders, products, inventory, sales, and financial data integration to help seller clients achieve their business goals quickly and easily without having to spend a lot of time learning complex software operations.<br>
Our application leases global servers from Amazon Cloud, and the processing of buyer personal information is highly secure and privacy-protected. We store this personal information on servers located in the country of origin, and only programs that meet specific usage scenarios can access it. We also regularly delete this information to comply with privacy laws in all countries. Therefore, our application can help seller clients securely and compliantly process buyer personal information and reduce potential legal risks.<br>
Meanwhile, our data analysis team will help seller clients understand their business situation and provide detailed sales reports, including sales, order quantity, and return rates, helping sellers understand their business status. We can also conduct competitive analysis so that they can better formulate their own sales strategies. By using our application and data analysis services, sellers can better understand market demand and trends, and formulate corresponding sales strategies. This will help them achieve better growth in a highly competitive market and achieve better performance. Our application also supports product delivery in both FBM and FBA modes to meet the different needs of sellers.<br>
Compared with generic management software services available on the market, our low-code technology offers greater flexibility to meet sellers' personalized needs and allows for rapid customization of the supply chain system process. Additionally, our dimensional analysis function can help sellers gain insight into their product and market performance, enabling them to develop more effective business strategies. We focus on helping seller clients improve business efficiency, reduce costs, and gain better growth opportunities.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题二：</div>
                <div class="q">Describe why you require Personally Identifiable Information to build your application or feature.<br>描述一下为什么你需要个人身份识别信息来构建你的应用程序或功能。</div>
                <div class="a">请你帮我简化下方正文，修改错别字，优化语句<br>
正文开始：<br>
目前我们的应用服务面向的卖家客户中，70%采用FBA模式交付，30%采用FBM模式交付。这些卖家需要使用“买家信息”进行物流面单填写及打印，实现仓储物流履约交付一体化流程。同时，部分买家需要向我们的服务的卖家客户索要发票。卖家希望实现系统一体化的自动开票流程，而发票资料需要使用到买家的部分个人信息资料。一些税务开具的税务数据信息需要备案，以备用于公司财务审计时使用。<br>
为了确保买家的个人身份识别信息得到有效的保护，我们将个人买家信息作为内核数据部分，以确保卖家客户无法批量获取相关数据信息。每次获取使用时，必须是以单个使用场景进行单个买家信息使用，确保数据不会被大规模采集。同时，我们设定了一定的使用过期机制，会在满足场景使用条件完成后进行数据自动物理删除。<br>
下面将详细的阐述一下我们服务的卖家获得买家个人信息的使用和系统流程：<br>
第一步：买家通过亚马逊平台购买了卖家一个以FBM模式交付的商品，系统会定时从SP-API获取订单，并注明该订单是以FBM模式交付，同时会进行城市和详细地址进行混淆加密（例如：*****），仅显示国家、州和亚马逊提供的邮箱信息。系统提供一个“查看地址”功能按钮，点击按钮后将向管理员发起一个查看请求，获得请求同意后，该地址会解密显示一小时，一小时后继续显示为混淆加密（例如：*****）效果，如果系统识别订单是FBA模式交付，则不提供该项功能。<br>
第二步：卖家客户的客服人员会根据买家所在地址在系统内安排并选择相应的物流及物流面单打印渠道，如果选择亚马逊面单的，我们会直接获取亚马逊SP-API的物流面单接口，然后直接执行打印，如果选择其他物流渠道，系统则会自动打印面单，系统内全程不会出现地址等信息。这时，系统内会获取州市区详细地址还有买家个人联系方式进行面单打印。<br>
第三步：卖家客户的仓储人员得到打印出来的面单和拣货打包商品的信息后，去仓储中心获取相关的商品，然后进行打包，然后将相应的物流面单贴至包裹表面，此操作卖家仓储中心会有全程的摄像头监控记录，影像记录保存7天，用于追踪及以确保个人买家信息未被相关人员收集。<br>
第四步：仓储人员会将相关快递包裹交付物流，卖家客户必须选择合法合规的物流公司进行合作，交付物流过程也有全程的监控记录。<br>
第五步：系统在获取物流信息回写后，确认买家已完成签收包裹后，系统订单上面的“查看地址”功能将自动消失，自此，全部人员已隔断再次查看买家信息的渠道。<br>
第六步：当买家向亚马逊索要发票时，系统自动识别，并获取“买家个人信息的”自动形成开票文件，并自动以邮件形式发送至亚马逊提供的买家个人联系邮箱。全过程卖家相关人员无法查看相关信息，完成此此项功能后，系统订单内显示标识“此订单已开票”，并会以订单号、开票结果等格式提供一份税务审计表格给财务人员备案，备案内出订单号，开具日期、开具国家、开具结果以外，不会出现其他买家个人信息，同时相关邮件由系统自动发出，也不会显示在邮箱内。（如果是FBA交付模式的订单，我们将从这步开始交付发票）<br>
第七步：系统设有定时任务，自动识别订单从下单日期起，30天自动物理删除全部买家个人信息资料，并会有提示“为确保个人信息安全，系统已删除相关买家信息资料，如您未完成发货和开票，请至亚马逊后台查询订单去继续完成”的提示语。<br>
第八步：完成履约交付、税务开票，结束流程。</div>
            </div>
            <div class="qaitem">
                <div class="title">问题三：</div>
                <div class="q">Do you support online merchants today?</div>
                <div class="a"></div>
            </div>
            <div class="qaitem">
                <div class="title">问题四：</div>
                <div class="q">List the online channels that you support today<br>列出你今天支持的在线渠道</div>
                <div class="a">我们目前主要支持三个主要电商平台：亚马逊、沃尔玛和Etsy。目前，我们服务的卖家客户中，亚马逊占据了89.38%，沃尔玛占据了7.39%，Etsy占据了3.23%。</div>
            </div>
            <div class="qaitem">
                <div class="title">问题五：</div>
                <div class="q">What differentiates your new feature/application from others applications in the category?<br>你们的新功能/应用程序与该类别中的其他应用程序有何不同之处？</div>
                <div class="a">我们的应用程序是专门为卖家客户企业设计的，提供完全定制化的全自动化和半自动化解决方案，以满足他们特定的流程衔接需求。相比于市面上的固定功能SAAS软件，我们的应用程序可以确保流程和信息的闭环，避免了信息泄露的风险。此外，我们是一个低代码企业管理软件，专注于提供流程、订单功能等解决方案，以帮助卖家客户企业更快速、更轻松地实现其业务目标，无需长时间学习复杂的软件操作。<br>
我们的应用程序租用了亚马逊云的全球服务器，涉及到买家个人信息的处理也是高度安全和隐私保护的。我们将这些个人信息存储在所在国家当地的服务器上，只有符合特定使用场景的程序才能进行调用，而且我们会定期删除这些信息，以遵守全球各国的隐私法律。因此，我们的应用程序能够帮助卖家客户企业安全合规地处理买家个人信息，并减少潜在的法律风险。<br>
同时，我们的数据分析团队将帮助卖家客户掌握他们的业务情况，提供详细的销售报告，包括销售额、订单数量和退货率等指标，帮助卖家了解他们的业务状况。我们还可以进行竞争分析，以便他们可以更好地制定自己的销售策略。通过使用我们的应用程序和数据分析服务，卖家可以更好地了解市场需求和趋势，并制定相应的销售策略。这将帮助他们在竞争激烈的市场中获得更好的成长，并实现更好的业绩。<br>
<br>
Our application is specifically designed for seller clients and provides fully customizable, automated and semi-automated solutions to meet their specific workflow needs. Unlike fixed-function SAAS software on the market, our application ensures closed loops of processes and information, avoiding the risk of information leakage. Additionally, we are a low-code enterprise management software that focuses on providing solutions for processes, order management, and more, to help seller clients achieve their business goals faster and easier without the need for long-term learning of complex software operations.<br>
Our application rents global servers from Amazon Cloud, and the processing of buyer personal information is highly secure and privacy-protected. We store this personal information on servers located in the buyer's country, and only programs that meet specific usage scenarios can make calls to them. We also regularly delete this information to comply with global privacy laws. Therefore, our application can help seller clients process buyer personal information safely and compliantly, reducing potential legal risks.<br>
At the same time, our data analysis team will help seller clients understand their business situation, providing detailed sales reports, including sales volume, order quantity, return rate, and other indicators, to help sellers understand their business conditions. We can also conduct competitive analysis so that they can better formulate their sales strategies. By using our application and data analysis services, sellers can better understand market demand and trends, and formulate corresponding sales strategies. This will help them achieve better growth and performance in a highly competitive market.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题六：</div>
                <div class="q">Please describe any country-specific functionality that you provide.<br>请描述一下您提供的任何针对特定国家/地区的功能。</div>
                <div class="a">我提供的所有功能都支持亚马逊全球站点，没有针对特定国家或地区的功能。<br>
<br>
All the features I provide support Amazon's global sites and there are no specific features for any country or region.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题七：</div>
                <div class="q">Please list the Amazon programs you intend to support through your application or functionality.<br>请列出您打算通过您的应用程序或功能支持的亚马逊计划。</div>
                <div class="a">接入SP-API主要提供给卖家的相关程序如下：<br>
订单管理：可以访问订单信息，包括订单状态、订单详情、订单历史记录等。可以创建、更新和取消订单。<br>
商品管理：可以访问商品信息，包括商品属性、价格、库存、图像等。可以创建、更新和删除商品。<br>
库存管理：可以访问库存信息，包括库存水平、配送中的订单、预定库存等。可以更新库存信息。<br>
物流管理：可以访问物流信息，包括配送方式、配送费用、发货地址等。可以创建、更新和取消物流订单。<br>
支付管理：可以访问支付信息，包括支付状态、付款金额、付款方式等。可以创建和取消付款订单。<br>
报告管理：可以访问各种报告信息，包括销售报告、库存报告、订单报告、退货报告等。<br>
商家信息管理：可以访问商家账户信息，包括账户余额、费用明细等。可以更新商家账户信息。<br>
卖家评价管理：可以访问买家的差评评价信息，帮助卖家实现自动化邀请评论等。<br>
广告管理：可以访问广告信息，包括广告状态、广告效果、广告费用等。可以创建、更新和删除广告。<br>
通知管理：可以接收和处理来自亚马逊的各种通知信息，包括订单通知、退货通知、库存通知等。<br>
VAT管理：可以访问卖家的VAT信息，包括VAT税率、VAT编号等，也可以创建、更新和取消VAT注册信息。这些功能可以帮助卖家更好地遵守当地税务规定，管理他们在亚马逊平台上的税务事务。<br>
这些功能可以帮助卖家更好地管理他们在亚马逊平台上的业务，提高效率，增加销售额。<br>
<br>
The following programs are provided to sellers for accessing the SP-API:<br>
Order management: Access to order information, including order status, order details, order history, etc. Ability to create, update, and cancel orders.<br>
Product management: Access to product information, including product attributes, prices, inventory, images, etc. Ability to create, update, and delete products.<br>
Inventory management: Access to inventory information, including inventory levels, orders in transit, reserved inventory, etc. Ability to update inventory information.<br>
Logistics management: Access to logistics information, including shipping methods, shipping costs, shipping addresses, etc. Ability to create, update, and cancel shipping orders.<br>
Payment management: Access to payment information, including payment status, payment amount, payment method, etc. Ability to create and cancel payment orders.<br>
Report management: Access to various report information, including sales reports, inventory reports, order reports, return reports, etc.<br>
Seller information management: Access to seller account information, including account balance, fee details, etc. Ability to update seller account information.<br>
Seller review management: Access to negative feedback and reviews from buyers, helping sellers to automate review invitations, etc.<br>
Advertising management: Access to advertising information, including advertising status, advertising effectiveness, advertising costs, etc. Ability to create, update, and delete advertisements.<br>
Notification management: Ability to receive and process various notification information from Amazon, including order notifications, return notifications, inventory notifications, etc.<br>
VAT management: Access to seller VAT information, including VAT tax rate, VAT number, etc. Ability to create, update, and cancel VAT registration information. These functions can help sellers better comply with local tax regulations and manage their tax affairs on the Amazon platform.<br>
These functions can help sellers better manage their business on the Amazon platform, improve efficiency, and increase sales.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题八：</div>
                <div class="q">List all outside parties with whom your organization shares Amazon Information and describe how your organization shares this information.<br>列出您的组织与之共享亚马逊信息的所有外部方，并描述您的组织如何共享此信息。</div>
                <div class="a">作为亚马逊公共开发者，我们非常重视用户数据的保护，遵守亚马逊隐私政策的要求是我们的首要任务。目前，我们仅与亚马逊官方合作的物流商进行连接，以实现打印FBM物流发货面单和FBA入库计划的发货物流请求。我们与这些物流商签订了保密协议，以确保用户数据的机密性和安全性。以下是几个亚马逊官方合作的物流商，他们具有数据接口功能：<br>
1、FedEx<br>
2、UPS<br>
3、DHL<br>
4、Royal Mail<br>
这些物流商都已经通过亚马逊的认证，与亚马逊的API平台进行连接，以提供更高效的物流服务。除了这些物流商之外，我们未与任何第三方共享任何来源于亚马逊SP-API的信息数据。<br>
<br>
As an Amazon public developer, we prioritize the protection of user data and complying with Amazon's privacy policy is our top priority. Currently, we only connect with Amazon's official logistics partners to enable printing of FBM logistics delivery notes and shipping requests for FBA inventory plans. We have signed confidentiality agreements with these logistics partners to ensure the confidentiality and security of user data. Here are a few of Amazon's official logistics partners with data interface capabilities:<br>
1、FedEx<br>
2、UPS<br>
3、DHL<br>
4、Royal Mail<br>
These logistics partners have all been certified by Amazon and connected to Amazon's API platform to provide more efficient logistics services. Apart from these logistics partners, we have not shared any information data originating from Amazon's SP-API with any third parties.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题九：</div>
                <div class="q">List all non-Amazon MWS sources where you retrieve Amazon Information.<br>请列出您检索亚马逊信息的所有非亚马逊 MWS 来源。</div>
                <div class="a">作为我们的亚马逊公共开发者，我们不会从非亚马逊 MWS 来源检索亚马逊信息。我们检索的所有亚马逊信息都直接来自亚马逊 SP API。遵守亚马逊的政策和指南以确保亚马逊信息的安全和隐私非常重要。同时，我们与卖家客户合同约定好禁止来源于非亚马逊 MWS 的信息进入系统内，以遵守各国法律要求。<br>
<br>
As a public developer for our company, we do not retrieve Amazon information from non-Amazon MWS sources. All Amazon information we retrieve comes directly from Amazon's SP API. It's important for us to comply with Amazon's policies and guidelines to ensure the security and privacy of Amazon information. Additionally, we have contracts with our seller clients that prohibit non-Amazon MWS sourced information from entering our system, in compliance with local laws.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十：</div>
                <div class="q">Describe the network protection controls used by your organization to restrict public access to databases, file servers, and desktop/developer endpoints.<br>描述您的组织用于限制对数据库、文件服务器和桌面/开发人员端点的公共访问的网络保护控制。</div>
                <div class="a">我们采取了多种措施来限制对数据库、文件服务器和桌面/开发人员端点的公共访问的网络保护控制，以确保我们的用户数据得到最大程度的保护和隐私。<br>
首先，我们使用了访问控制列表（ACL）来限制对文件服务器和数据库的访问。ACL允许我们精确地定义哪些用户或用户组可以访问特定的资源，并防止未经授权的访问。<br>
其次，我们使用了虚拟专用网络（VPN）来限制对桌面/开发人员端点的访问。通过VPN，我们可以安全地连接到我们的网络，而不必担心数据被截获或泄露。<br>
此外，我们还使用了网络防火墙，以便检测和阻止恶意网络流量。我们使用了Amazon Web Services (AWS)提供的AWS WAF和AWS Shield等服务，以及腾讯云提供的云防火墙等服务。这些服务可以帮助我们检测和阻止针对我们的应用程序的恶意流量，保护我们的用户数据免受网络攻击和安全漏洞的影响。<br>
最后，我们定期进行安全审计和漏洞扫描，以检测和纠正任何安全漏洞。我们使用AWS提供的Amazon Inspector和AWS Config等服务，以及腾讯云提供的云安全审计和漏洞扫描等服务。这些服务可以自动检测我们的应用程序和网络组件中的安全漏洞，并向我们提供详细的报告和建议，以便我们及时修复漏洞并提高安全性。<br>
通过以上措施的组合使用，我们的应用程序能够保障用户数据的安全和隐私，并遵守全球各国的隐私法律。同时，我们也将继续秉承安全合规的原则，不断提高我们的安全性和保护能力。<br>
<br>
We have implemented several measures to restrict public access to our databases, file servers, and desktop/developer endpoints through network security controls to ensure the maximum protection and privacy of our user data.<br>
Firstly, we use Access Control Lists (ACLs) to restrict access to our file servers and databases. ACLs allow us to precisely define which users or user groups can access specific resources, preventing unauthorized access.<br>
Secondly, we use Virtual Private Networks (VPNs) to limit access to our desktop/developer endpoints. With a VPN, we can securely connect to our network without worrying about data interception or leakage.<br>
In addition, we also use network firewalls to detect and block malicious network traffic. We use services like AWS WAF and AWS Shield provided by Amazon Web Services (AWS), as well as Cloud Firewall provided by Tencent Cloud. These services help us detect and block malicious traffic targeted at our applications, protecting our user data from network attacks and security vulnerabilities.<br>
Finally, we conduct regular security audits and vulnerability scans to detect and correct any security vulnerabilities. We use services such as Amazon Inspector and AWS Config provided by AWS, as well as Cloud Security Audit and Vulnerability Scan provided by Tencent Cloud. These services automatically detect security vulnerabilities in our applications and network components, and provide us with detailed reports and recommendations to fix vulnerabilities and improve security.<br>
Through the combination of these measures, our application can ensure the security and privacy of user data and comply with privacy laws around the world. We will continue to adhere to the principles of security and compliance, constantly improving our security and protection capabilities.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十一：</div>
                <div class="q">Describe how your organization individually identifies employees who have access to Amazon Information, and restricts employee access to Amazon information on a need- to-know basis.<br>描述您的组织如何单独识别有权访问亚马逊信息的员工，并在需要知道的基础上限制员工访问亚马逊信息。</div>
                <div class="a">我们采取了多种措施来单独识别有权访问亚马逊信息的员工，并在需要知道的基础上限制员工访问亚马逊信息。首先，我们实施了身份验证和访问控制策略，确保只有经过身份验证的员工才能访问亚马逊信息。我们采用了多因素身份验证机制，例如使用多个身份验证因素，例如用户名、密码和安全令牌等，以确保只有授权人员才能访问敏感信息。我们使用的一些工具包括Okta和Auth0，这些工具提供了强大的身份验证和访问控制功能，以确保只有经过身份验证的用户才能访问信息。<br>
其次，我们使用了访问审计和监视工具来监控员工访问亚马逊信息的活动。这些工具包括Amazon CloudTrail和AWS Config等，可以记录员工对亚马逊信息的所有访问，以便我们可以检测和调查任何未经授权的访问。此外，我们还实施了实时监视和警报机制，以及对访问日志的分析和审计，以识别潜在的访问异常或安全威胁。<br>
最后，我们采用了最小权限原则，确保每个员工只能访问其工作职责所需的最低权限级别。我们实施了基于角色的访问控制策略，以确保员工只能访问他们需要的信息。我们使用的一些工具包括Amazon Identity and Access Management (IAM)和AWS Organizations等，这些工具提供了灵活的权限管理和策略控制功能，以确保员工只能访问其所需的资源和信息。<br>
综上所述，我们的组织采用了身份验证和访问控制、访问审计和监视、以及最小权限原则等措施来单独识别有权访问亚马逊信息的员工，并在需要知道的基础上限制员工访问亚马逊信息。我们使用了多种工具，如Okta、Auth0、Amazon CloudTrail、AWS Config、IAM和AWS Organizations等，以确保我们的访问控制和监视措施得到有效实施。同时，我们也将不断改进和更新我们的安全和隐私策略，以适应不断变化的威胁和法规要求，保障我们用户的数据安全和隐私。<br>
<br>
We have implemented multiple measures to identify employees who have access to Amazon information and limit their access to only what is necessary. Firstly, we have implemented authentication and access control policies to ensure that only authenticated employees can access Amazon information. We use multi-factor authentication mechanisms such as usernames, passwords, and security tokens to ensure that only authorized personnel can access sensitive information. We use tools such as Okta and Auth0, which provide powerful authentication and access control features to ensure that only authenticated users can access information.<br>
Secondly, we use access auditing and monitoring tools to monitor employee activity when accessing Amazon information. These tools include Amazon CloudTrail and AWS Config, which record all employee access to Amazon information so that we can detect and investigate any unauthorized access. In addition, we have implemented real-time monitoring and alert mechanisms, as well as analysis and auditing of access logs, to identify potential access anomalies or security threats.<br>
Finally, we have adopted the principle of least privilege to ensure that each employee can only access the minimum level of permissions required for their job duties. We have implemented role-based access control policies to ensure that employees can only access the information they need. We use tools such as Amazon Identity and Access Management (IAM) and AWS Organizations, which provide flexible permission management and policy control features to ensure that employees can only access the resources and information they need.<br>
In summary, our organization has implemented authentication and access control, access auditing and monitoring, and the principle of least privilege to identify employees who have access to Amazon information and limit their access to only what is necessary. We use various tools such as Okta, Auth0, Amazon CloudTrail, AWS Config, IAM, and AWS Organizations to ensure that our access control and monitoring measures are effectively implemented. We will continue to improve and update our security and privacy policies to adapt to evolving threats and regulatory requirements, ensuring the security and privacy of our users' data.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十二：</div>
                <div class="q">Describe the mechanism your organization has in place to monitor and prevent Amazon Information from being accessed from employee personal devices (such as USB flash drives, cellphones) and how are you alerted in the event such incidents occur.<br>描述您的组织为监控和防止从员工个人设备（例如 USB 闪存驱动器、手机）访问亚马逊信息而采取的机制，以及在发生此类事件时您如何收到警报。</div>
                <div class="a">我们非常注重保护客户和亚马逊的数据安全和隐私。为了防止员工从个人设备（例如 USB 闪存驱动器、手机）访问亚马逊信息，我们实施了以下机制：<br>
设备管理政策：我们制定了设备管理政策，要求所有员工只能使用由公司分配的设备来访问亚马逊信息。这些设备受到强密码和双因素身份验证的保护，并且只有经过授权的员工可以使用它们。同时，我们禁止员工将亚马逊信息存储在个人设备上。<br>
数据加密：我们对所有存储在公司设备和亚马逊云服务器上的数据进行加密。我们使用的加密方法包括AES和RSA，并且确保加密密钥安全存储。此外，我们还定期检查加密算法和密钥的强度，并及时升级加密措施。<br>
网络访问控制：我们使用细粒度的网络访问控制，限制员工访问亚马逊信息的范围。只有经过授权的员工才能访问特定的网络资源，而其他资源则被禁止访问。<br>
安全培训和教育：我们定期开展安全培训和教育，教授员工如何识别和应对安全威胁。我们特别强调员工如何保护客户数据和敏感信息，并提供相关的安全工具和软件。<br>
如果发生员工从个人设备访问亚马逊信息的事件，我们会立即收到警报并采取相应的措施。我们使用的工具包括入侵检测系统（IDS）和安全信息和事件管理（SIEM）平台，它们可以帮助我们监测网络流量和识别异常行为。此外，我们还定期进行内部审核和安全检查，以确保我们的安全措施符合最佳实践和法律法规要求。<br>
总之，我们非常重视客户和亚马逊的数据安全和隐私，采取了多种措施来保护数据不被员工的个人设备访问。如果发生安全事件，我们会立即采取行动并向亚马逊汇报。 <br>
<br>
We place great importance on protecting the security and privacy of our customers' and Amazon's data. To prevent employees from accessing Amazon information through personal devices such as USB flash drives or phones, we have implemented the following measures:<br>
Device management policy: We have established a device management policy that requires all employees to only use company-assigned devices to access Amazon information. These devices are protected by strong passwords and two-factor authentication, and only authorized personnel can use them. Additionally, we prohibit employees from storing Amazon information on personal devices.<br>
Data encryption: We encrypt all data stored on company devices and Amazon cloud servers. Our encryption methods include AES and RSA, and we ensure that encryption keys are securely stored. Furthermore, we regularly review the strength of encryption algorithms and keys, and promptly upgrade our encryption measures.<br>
Network access control: We use fine-grained network access control to limit employee access to Amazon information. Only authorized personnel can access specific network resources, while other resources are prohibited from access.<br>
Security training and education: We conduct regular security training and education to teach employees how to recognize and respond to security threats. We specifically emphasize how to protect customer data and sensitive information, and provide relevant security tools and software.<br>
If an incident occurs where an employee accesses Amazon information through a personal device, we will immediately receive an alert and take appropriate measures. The tools we use include intrusion detection systems (IDS) and security information and event management (SIEM) platforms, which help us monitor network traffic and identify anomalous behavior. In addition, we conduct regular internal audits and security checks to ensure our security measures comply with best practices and legal requirements.<br>
In summary, we place great importance on the security and privacy of our customers' and Amazon's data, and have taken various measures to prevent data access through employees' personal devices. If a security incident occurs, we will take immediate action and report to Amazon.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十三：</div>
                <div class="q">Provide your organization's privacy and data handling policies to describe how Amazon data is collected, processed, stored, used, shared and disposed. You may provide this in the form of a public website URL.<br>提供贵公司的隐私和数据处理政策，描述如何收集、处理、存储、使用、共享和处理Amazon数据。您可以提供公共网站URL形式。</div>
                <div class="a">首席软件官的《隐私条款》URL：https://www.sxrjg.com/privacypolicy</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十四：</div>
                <div class="q">Describe where your organization stores Amazon Information at rest and provide details on any encryption algorithm used.<br>描述您的组织在何处存储亚马逊静态信息，并提供有关使用的任何加密算法的详细信息。</div>
                <div class="a">我们的组织仅在符合特定使用场景的程序调用时存储亚马逊静态信息，并且仅存储有关买家的个人身份信息（PII）。这些数据存储在我们租用的亚马逊云全球服务器上，位于所在国家当地。我们使用加密算法对这些数据进行了加密，以确保数据的安全性。我们使用了行业标准的AES加密算法，这是一个可靠的加密算法，已被广泛接受和使用。<br>
我们的组织采用了细粒度的访问控制策略来限制对PII数据的访问。我们使用了AWS Identity and Access Management（IAM）来管理对亚马逊云资源的访问权限，并使用AWS Key Management Service（KMS）来保护我们的数据密钥。我们只授权经过身份验证的人员访问PII数据，而且只授予他们所需的最小权限，以确保PII数据的机密性和完整性。<br>
我们的组织使用审计日志来检测安全事件并发出警报。我们使用AWS CloudTrail记录API调用和事件，以帮助我们了解谁对我们的资源进行了哪些操作。我们还使用AWS Config检查我们的资源配置，以确保我们符合AWS最佳实践和符合标准的配置。<br>
在每次发布之前，我们都会扫描应用程序代码以查找漏洞。我们使用的工具包括Amazon CodeGuru Reviewer，SonarQube和Checkmarx。我们还采用正式的变更管理流程来定义测试、验证和批准变更的职责，并限制可以执行这些操作的人员的访问权限。<br>
除了PII数据外，我们的组织还将其他数据存储在腾讯云上。我们使用腾讯云提供的加密功能来保护这些数据的安全性，并采用类似于AWS的细粒度访问控制策略来限制对这些数据的访问。<br>
总之，我们的应用程序能够帮助卖家客户企业安全合规地处理买家个人信息，并减少潜在的法律风险。同时，我们的组织采取了各种措施来保护PII数据和其他数据的安全性和机密性，以确保符合全球各国的隐私法律。<br>
<br>
Our organization only stores Amazon static information containing personal identifying information (PII) of buyers in specific program calls, and this data is encrypted using industry-standard AES encryption. We store this data on Amazon Cloud global servers rented locally in the country of operation to ensure data security.<br>
To restrict access to PII data, our organization has implemented fine-grained access control policies using AWS Identity and Access Management (IAM). We also use AWS Key Management Service (KMS) to protect our data keys, and only authorize authenticated personnel with the minimum necessary permissions to access PII data, ensuring confidentiality and integrity.<br>
We use audit logs to detect security events and issue alerts. AWS CloudTrail is used to record API calls and events to help us understand who has performed what actions on our resources. We also use AWS Config to check our resource configuration to ensure compliance with AWS best practices and standards.<br>
Before each release, we scan the application code for vulnerabilities using tools like Amazon CodeGuru Reviewer, SonarQube, and Checkmarx. We also have a formal change management process to define the responsibilities for testing, verifying, and approving changes, and limit access to personnel who can perform these operations.<br>
In addition to PII data, our organization stores other data on Tencent Cloud, using encryption provided by Tencent Cloud to protect the security of this data. Similar to AWS, we have implemented fine-grained access control policies to restrict access to this data.<br>
Overall, our application helps seller-customer enterprises process buyer personal information securely and compliantly, reducing potential legal risks. At the same time, our organization has taken various measures to protect the security and confidentiality of PII and other data to ensure compliance with privacy laws worldwide.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十五：</div>
                <div class="q">Describe how your organization backups or archives Amazon Information and provide details on any encryption algorithm used.<br>描述您的组织如何备份或存档亚马逊信息，并提供有关使用的任何加密算法的详细信息。</div>
                <div class="a">我们非常注重备份和存档亚马逊信息的安全性和保密性。对于涉及到买家个人信息的部分，我们的应用程序租用了亚马逊云的全球服务器，涉及到买家个人信息的处理也是高度安全和隐私保护的。我们将这些个人信息存储在所在国家当地的服务器上，只有符合特定使用场景的程序才能进行调用，而且我们会定期删除这些信息，以遵守全球各国的隐私法律。<br>
为了确保这些信息的完整性和可靠性，我们使用了多层次的安全措施和技术，如加密、存储和备份。我们定期对亚马逊信息进行备份，以确保在系统故障或数据丢失的情况下，能够恢复数据。我们使用 Amazon S3 存储服务来存储备份数据。根据不同的业务需求，我们将数据存档到 Amazon Glacier 中，以实现长期存储和备份。Amazon Glacier 提供了安全、可扩展的云存储，同时具有低延迟和高吞吐量。<br>
为了保护备份和存档的数据，我们使用的加密算法包括 AES 和 RSA。AES 是一种对称加密算法，使用相同的密钥来加密和解密数据。我们使用 AES-256 加密算法来保护备份和存档的数据。RSA 是一种非对称加密算法，使用公钥加密数据，私钥解密数据。我们使用 RSA-2048 加密算法来保护存储在数据库中的敏感信息。<br>
在数据传输方面，我们使用 SSL/TLS 加密协议来保护数据在传输过程中的安全性。SSL/TLS 协议提供了加密传输和认证功能，以确保数据在传输过程中不会被窃听或篡改。我们使用 AWS Certificate Manager 来管理 SSL/TLS 证书，以确保证书的合法性和有效性。<br>
最后，为了确保备份数据的完整性和可用性，我们定期对备份数据进行验证和测试。我们使用 AWS CloudFormation、AWS CodePipeline、AWS CodeCommit 等工具来自动化备份和测试过程，以确保备份数据的完整性和可靠性。我们遵守亚马逊的隐私和数据处理政策，并尽力保护用户数据的安全和保密，从而帮助卖家客户企业安全合规地处理买家个人信息，并减少潜在的法律风险。<br>
<br>
We take the security and confidentiality of Amazon information very seriously. For the parts that involve buyer's personal information, our application rents global servers from Amazon Cloud, and the processing of personal information is highly secure and private. We store this personal information on servers located in the buyer's local country, and only programs that meet specific usage scenarios can access it. We also regularly delete this information to comply with privacy laws in various countries around the world.<br>
To ensure the integrity and reliability of this information, we use multi-level security measures and technologies, such as encryption, storage, and backup. We regularly back up Amazon information to ensure data can be restored in case of system failure or data loss. We use Amazon S3 storage service to store backup data. Depending on the different business requirements, we archive data to Amazon Glacier for long-term storage and backup. Amazon Glacier provides secure, scalable cloud storage with low latency and high throughput.<br>
To protect backup and archive data, we use encryption algorithms such as AES and RSA. AES is a symmetric encryption algorithm that uses the same key to encrypt and decrypt data. We use the AES-256 encryption algorithm to protect backup and archive data. RSA is an asymmetric encryption algorithm that uses a public key to encrypt data and a private key to decrypt data. We use the RSA-2048 encryption algorithm to protect sensitive information stored in the database.<br>
In terms of data transmission, we use SSL/TLS encryption protocols to ensure the security of data during transmission. The SSL/TLS protocol provides encryption transmission and authentication functions to ensure that data is not intercepted or tampered with during transmission. We use AWS Certificate Manager to manage SSL/TLS certificates to ensure the legality and validity of certificates.<br>
Finally, to ensure the integrity and availability of backup data, we regularly verify and test backup data. We use tools such as AWS CloudFormation, AWS CodePipeline, and AWS CodeCommit to automate the backup and testing process, ensuring the integrity and reliability of backup data. We comply with Amazon's privacy and data processing policies and do our best to protect the security and confidentiality of user data, helping seller customers comply with laws and regulations for handling buyer's personal information and reduce potential legal risks.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十六：</div>
                <div class="q">Describe how your organization monitors, detects, and logs malicious activity in your application(s).<br>请描述贵组织如何监测、检测和记录应用程序中的恶意活动。</div>
                <div class="a">我们非常重视应用程序中的恶意活动监测、检测和记录。以下是我们采取的一些措施：
首先，我们实施了实时监测措施，通过使用专业的安全工具如AWS CloudWatch、AWS Config、AWS GuardDuty和AWS Security Hub来监测应用程序的运行状况，并检测任何可疑的恶意活动。我们的系统会及时发出警报提示，通知我们的响应团队和管理层，以便快速采取措施应对这些问题。<br>
其次，我们使用了先进的安全技术，包括基于云的防火墙、入侵检测系统和数据加密。我们使用了AWS WAF (Web Application Firewall)来保护应用程序和数据的安全，并且使用AWS KMS (Key Management Service)对数据进行加密。我们还使用了细粒度的访问控制，限制只有授权人员才能访问个人身份信息。<br>
第三，我们建立了完善的审计日志系统，以记录所有应用程序的活动，并且对所有日志进行监测和检测。我们的日志记录包括所有用户登录信息、操作记录和异常活动，我们使用AWS CloudTrail来记录所有API调用并存储日志，以便我们可以及时发现和处理任何恶意行为。<br>
第四，我们定期进行漏洞扫描和渗透测试，以评估应用程序和网络组件的安全性。我们使用工具如AWS Inspector来执行安全漏洞扫描和安全漏洞分析。我们还使用了正式的变更管理流程，以确保应用程序的任何变更都经过测试、验证和批准，并且只有授权人员才能执行这些操作。<br>
最后，我们与亚马逊安全团队密切合作，及时共享任何发现的安全问题，并采取协同行动来应对任何可能的威胁。我们还订阅了AWS Security Bulletin和AWS Threat Intelligence服务，以获取最新的安全漏洞和攻击趋势信息，并采取适当的措施来防范和应对攻击。<br>
总之，我们非常重视应用程序中的恶意活动监测、检测和记录，采取了多种措施来保障应用程序和数据的安全。我们将继续努力改进我们的安全措施，以确保我们的应用程序和数据得到最大程度的保护。<br>
<br>
We take the monitoring, detection, and logging of malicious activity in our applications very seriously. Here are some measures we have implemented:<br>
First, we have implemented real-time monitoring measures by using professional security tools such as AWS CloudWatch, AWS Config, AWS GuardDuty, and AWS Security Hub to monitor the application's performance and detect any suspicious malicious activity. Our system sends timely alert notifications to our response team and management, enabling us to quickly respond to these issues.<br>
Second, we use advanced security technologies, including cloud-based firewalls, intrusion detection systems, and data encryption. We use AWS WAF (Web Application Firewall) to protect the application and data's security and use AWS KMS (Key Management Service) to encrypt data. We also use granular access control to restrict access to personal identification information to authorized personnel only.<br>
Third, we have established a comprehensive audit logging system to record all application activities and monitor and detect all logs. Our logging includes all user login information, operation records, and abnormal activity. We use AWS CloudTrail to record all API calls and store logs, so we can quickly discover and address any malicious behavior.<br>
Fourth, we regularly perform vulnerability scanning and penetration testing to assess the security of the application and network components. We use tools such as AWS Inspector to perform security vulnerability scanning and analysis. We also use a formal change management process to ensure that any changes to the application undergo testing, validation, and approval, and that only authorized personnel can perform these operations.<br>
Finally, we work closely with the Amazon security team to promptly share any security issues discovered and take collaborative action to address any potential threats. We also subscribe to AWS Security Bulletin and AWS Threat Intelligence services to get the latest security vulnerabilities and attack trends information and take appropriate measures to prevent and respond to attacks.<br>
In summary, we take the monitoring, detection, and logging of malicious activity in our applications very seriously and have implemented various measures to ensure the security of our applications and data. We will continue to improve our security measures to ensure that our applications and data are maximally protected.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十七：</div>
                <div class="q">Summarize the steps taken within your organization's incident response plan to handle database hacks, unauthorized access, and data leaks.<br>请总结你的组织在应对数据库黑客、未经授权访问和数据泄漏方面所采取的应急响应计划步骤。</div>
                <div class="a">首先，我们将实施紧急通知程序，以确保所有相关人员能够迅速获得有关事件的信息。一旦发现任何数据库黑客、未经授权访问和数据泄漏等潜在的数据安全问题，我们将立即启动该计划，并通知响应团队和管理层。我们还会向指定的亚马逊邮箱3p-security@amazon.com发送警报提示，以确保获得专业的支持和帮助。<br>
其次，我们将采取快速而系统化的措施，以确定问题的根源并尽快解决它。我们将立即隔离受影响的数据库，保护现场，以确保不进一步破坏或数据泄露。必要时启动备份系统，以便尽可能快速地恢复业务正常运营。我们将进行全面的风险评估和威胁分析，并在必要时将第三方专家引入来提供专业的安全咨询和技术支持。同时，我们将采取有效的措施，遏制事件的进一步扩散，并保持高度透明和及时沟通，以确保客户和业务的数据安全得到最大程度的保障。<br>
最后，我们将定期审查和改进我们的应急响应计划，以保持其适应性和效力。我们将通过定期演习和模拟事件的方式来测试和改进我们的计划，以确保我们能够及时、有效地应对各种安全风险。我们还将持续遵循亚马逊的安全最佳实践和标准，以保护我们的应用程序和数据的安全性。<br>
总之，我们的组织已经制定了全面的应急响应计划，并采取了多种措施来应对数据安全问题。我们将持续改进我们的计划，并保持高度透明和及时沟通，以确保我们的客户和业务的数据安全得到最大程度的保障。<br>
<br>
Firstly, we will implement an emergency notification procedure to ensure that all relevant parties can quickly receive information about the event. In the event of any potential data security issues such as database hacks, unauthorized access, and data breaches, we will immediately initiate this plan and notify response teams and management. We will also send alert notifications to the designated Amazon email address 3p-security@amazon.com to ensure professional support and assistance.<br>
Secondly, we will take swift and systematic measures to identify the root cause of the problem and resolve it as soon as possible. We will immediately isolate the affected databases, secure the site to ensure no further damage or data leaks occur. We will activate backup systems if necessary to restore business operations as quickly as possible. We will conduct a comprehensive risk assessment and threat analysis, and bring in third-party experts if necessary to provide professional security consulting and technical support. At the same time, we will take effective measures to contain the spread of the event and maintain a high degree of transparency and timely communication to ensure maximum protection of customer and business data security.<br>
Finally, we will regularly review and improve our emergency response plan to maintain its adaptability and effectiveness. We will test and improve our plan by regularly conducting drills and simulating events to ensure that we can respond to various security risks promptly and effectively. We will also continue to follow Amazon's best security practices and standards to protect the security of our applications and data.<br>
In summary, our organization has developed a comprehensive emergency response plan and taken multiple measures to address data security issues. We will continue to improve our plan and maintain a high degree of transparency and timely communication to ensure maximum protection of customer and business data security.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十八：</div>
                <div class="q">How do you enforce password management practices throughout the organization as it relates to required length, complexity (upper/lower case, numbers, special character) and expiration period?<br>你的组织如何强制执行密码管理规范，包括要求密码长度、复杂度（大小写、数字、特殊字符）和到期时间？</div>
                <div class="a">我们非常重视密码管理规范的执行，以确保我们的应用程序和数据受到适当的保护。<br>
首先，我们要求所有用户密码必须包含至少8个字符，并且必须使用大写字母、小写字母、数字和特殊字符中的至少三种。此外，我们要求用户在60天内更改其密码，并限制他们在24小时内不能使用旧密码。<br>
其次，我们使用双因素身份验证（2FA）来增强用户的密码安全性。我们强制要求所有用户使用2FA进行身份验证，这可以提供额外的安全层，保护用户账户和个人身份信息的安全性。<br>
我们还采用了密码哈希和盐值加密技术来保护用户密码的机密性。我们使用亚马逊提供的密码哈希和盐值加密服务对所有用户密码进行加密，并且确保只有授权人员才能访问密码数据。<br>
最后，我们定期审查我们的密码管理规范，并进行漏洞扫描和渗透测试来检测安全漏洞和弱点。我们采用严格的变更管理流程来定义测试、验证和批准变更的职责，并限制可以执行这些操作的人员的访问权限。<br>
总之，我们非常注重密码管理规范的执行，采取了多种措施来确保密码的安全性，并遵循亚马逊的安全最佳实践和标准，以保护我们的应用程序和数据的安全性。<br>
<br>
We take password management very seriously to ensure the security of our applications and data.<br>
Firstly, we require that all user passwords contain at least 8 characters and must include at least three of the following: uppercase letters, lowercase letters, numbers, and special characters. Additionally, we require users to change their passwords within 60 days and restrict them from using old passwords within 24 hours.<br>
Secondly, we enhance password security with two-factor authentication (2FA). We enforce the use of 2FA for all users, which provides an additional layer of security to protect user accounts and personal identity information.<br>
We also use password hashing and salting encryption technology to protect the confidentiality of user passwords. We use Amazon's password hashing and salting encryption service to encrypt all user passwords and ensure that only authorized personnel can access password data.<br>
Finally, we regularly review our password management policies and conduct vulnerability scanning and penetration testing to detect security vulnerabilities and weaknesses. We follow strict change management processes to define the responsibilities for testing, validation, and approval of changes, and limit access to personnel who can perform these operations.<br>
In summary, we place great importance on enforcing password management policies, have taken various measures to ensure password security, and follow Amazon's security best practices and standards to protect the security of our applications and data.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题十九：</div>
                <div class="q">How is Personally Identifiable Information (PII) protected during testing?<br>测试期间如何保护个人身份信息 (PII)？</div>
                <div class="a">作为亚马逊SP-API公开开发者，我们非常重视个人身份信息（PII）的保护。在测试期间，我们采取多种措施来确保PII的安全性。<br>
首先，我们会在测试环境中使用虚拟的PII数据，以避免在测试过程中使用真实的PII数据。这样可以确保我们的测试不会影响任何真实的PII数据，并且避免意外的数据泄露。<br>
其次，我们采用严格的访问控制措施来限制PII数据的访问。我们使用细粒度的访问控制来确保只有授权人员才能访问PII数据，并在测试期间对访问权限进行额外的监控和审计。<br>
我们还会采用加密技术来保护PII数据的机密性。我们使用亚马逊提供的KMS服务对PII数据进行加密，并确保只有授权人员才能解密数据。这可以保护PII数据在传输和存储过程中的安全性。<br>
最后，我们会定期审查我们的测试环境和应用程序设置，以确保我们的PII数据受到适当的保护。我们会使用亚马逊提供的安全性工具和服务来监控和审计我们的测试环境，并及时采取措施防止任何安全事件的发生。<br>
总之，我们非常注重PII数据的保护，并采取了多种措施来确保在测试期间PII数据的安全性。我们遵循亚马逊的安全最佳实践和标准，并使用亚马逊提供的安全性工具和服务来保护我们的应用程序和数据。我们定期审查我们的安全设置，以确保我们的应用程序和数据受到适当的保护。<br>
<br>
As an Amazon SP-API public developer, we place great emphasis on protecting personal identifying information (PII). During testing, we take multiple measures to ensure the security of PII.<br>
Firstly, we use virtual PII data in our testing environment to avoid using real PII data. This ensures that our testing does not affect any real PII data and prevents accidental data leaks.<br>
Secondly, we implement strict access control measures to limit access to PII data. We use fine-grained access control to ensure that only authorized personnel can access PII data and monitor and audit access permissions during testing.<br>
We also use encryption technology to protect the confidentiality of PII data. We use Amazon's KMS service to encrypt PII data and ensure that only authorized personnel can decrypt the data. This protects PII data during transmission and storage.<br>
Finally, we regularly review our testing environment and application settings to ensure that our PII data is adequately protected. We use Amazon's security tools and services to monitor and audit our testing environment and take measures to prevent any security incidents from occurring.<br>
In summary, we place great emphasis on protecting PII data and take multiple measures to ensure the security of PII data during testing. We follow Amazon's security best practices and standards and use Amazon's security tools and services to protect our applications and data. We regularly review our security settings to ensure that our applications and data are adequately protected.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题二十：</div>
                <div class="q">What measures are taken to prevent exposure of credentials?<br>采取了哪些措施来防止凭据暴露？</div>
                <div class="a">为了确保亚马逊SP-API公开开发者的安全性，我们采取了多种措施来防止凭据暴露和保护我们的应用程序和数据。我们严格遵循亚马逊的安全最佳实践和标准，并使用亚马逊提供的安全性工具和服务来保护我们的应用程序和数据，包括亚马逊IAM、KMS和CloudTrail等服务。我们定期审查我们的安全设置，以确保我们的应用程序和数据受到适当的保护。<br>
为了进一步保护我们的凭据，我们采用最佳的凭据保护实践，包括使用密钥存储库和加密技术来保护我们的凭据。我们还采用多因素身份验证（MFA）来保护我们的开发者账户和凭据，确保只有经过身份验证的用户才能访问敏感信息。此外，我们还定期进行漏洞扫描和安全测试，以确保我们的应用程序和系统没有安全漏洞和弱点，并定期更新我们的应用程序和依赖库，以确保我们的应用程序和系统不会受到已知的漏洞和攻击。<br>
为了确保我们的开发者团队了解最佳的安全实践和操作指南，我们进行了安全培训，并通过检查我们的代码库和应用程序日志来监控异常活动，并及时采取措施防止任何安全事件的发生。<br>
除此之外，我们还采取了密码策略、访问控制、加密存储和多因素认证等措施来增加凭据保护的安全性，并定期对员工进行安全培训，以提高其安全意识和对凭据保护的重视程度。通过这些措施，我们可以确保亚马逊SP-API公开开发者的安全性得到了充分的保障。<br>
<br>
To ensure the security of Amazon SP-API developers, we have taken multiple measures to prevent credential exposure and protect our applications and data. We strictly adhere to Amazon's security best practices and standards and utilize Amazon's security tools and services such as IAM, KMS, and CloudTrail to protect our applications and data. We regularly review our security settings to ensure our applications and data are adequately protected.<br>
To further protect our credentials, we employ best credential protection practices, including using key stores and encryption techniques to safeguard our credentials. We also use multi-factor authentication (MFA) to protect our developer accounts and credentials, ensuring only authenticated users can access sensitive information. Additionally, we regularly conduct vulnerability scanning and security testing to ensure our applications and systems have no security vulnerabilities or weaknesses. We also regularly update our applications and dependency libraries to ensure our applications and systems are not vulnerable to known vulnerabilities and attacks.<br>
To ensure our developer team is knowledgeable about best security practices and operational guidelines, we provide security training and monitor abnormal activity in our code repository and application logs, taking timely action to prevent any security incidents.<br>
In addition, we employ password policies, access controls, encrypted storage, and multi-factor authentication to enhance the security of our credential protection. We also regularly train our employees on security to increase their security awareness and importance of credential protection. Through these measures, we can ensure that the security of Amazon SP-API developers is fully protected.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题二十一：</div>
                <div class="q">How do you track remediation progress of findings identified from vulnerability scans and penetration tests?<br>您如何跟踪从漏洞扫描和渗透测试中确定的结果的修复进度？</div>
                <div class="a">我们采用漏洞跟踪工具来跟踪所有发现的漏洞，并在修复漏洞后更新漏洞状态。我们的开发团队在收到漏洞报告后会进行评估和优先级排序，并在最短时间内进行修复。我们跟踪漏洞修复的进度，并向有关方面发送修复进度更新。我们还会定期回顾漏洞修复流程，并进行必要的改进，以确保漏洞得到及时修复并提高我们的安全性能。<br>
我们的漏洞跟踪工具包括漏洞管理平台和团队内部的协作工具。漏洞管理平台会收集和跟踪漏洞报告，并为每个漏洞分配优先级和状态，例如“待评估”、“待修复”、“修复中”和“已修复”。团队成员可以通过平台查看漏洞报告，并与漏洞报告的提供者进行沟通。在修复漏洞后，我们会更新漏洞状态并将其标记为“已修复”。此外，我们还会使用内部协作工具，如Jira，来加强团队内部沟通，以确保漏洞得到及时修复。<br>
我们对漏洞修复的进度进行定期回顾，并制定改进计划。我们会收集和分析漏洞修复的数据，例如平均修复时间和漏洞重新打开率，并将其用于改进我们的漏洞修复流程。我们还会对漏洞跟踪工具进行评估，并考虑其他的漏洞管理工具和技术，以进一步提高我们的安全性能。<br>
总之，我们采用漏洞跟踪工具来跟踪漏洞修复进度，并定期回顾我们的漏洞修复流程，并进行必要的改进，以确保漏洞得到及时修复并提高我们的安全性能。<br>
<br>
We use a vulnerability tracking tool to keep track of all discovered vulnerabilities and update their status after they have been fixed. Our development team evaluates and prioritizes reported vulnerabilities and fixes them as quickly as possible. We monitor the progress of vulnerability fixes and provide updates to relevant parties. We also regularly review our vulnerability fix process and make necessary improvements to ensure timely fixes and improve our security performance.<br>
Our vulnerability tracking tool includes a vulnerability management platform and internal collaboration tools. The platform collects and tracks vulnerability reports and assigns priority and status to each vulnerability, such as "under evaluation," "pending fix," "being fixed," and "fixed." Team members can view vulnerability reports through the platform and communicate with the vulnerability reporters. After fixing a vulnerability, we update its status and mark it as "fixed." In addition, we use internal collaboration tools like Jira to enhance communication within the team and ensure timely vulnerability fixes.<br>
We regularly review the progress of vulnerability fixes and develop improvement plans. We collect and analyze data on vulnerability fixes, such as average fix time and vulnerability re-open rate, and use it to improve our vulnerability fix process. We also evaluate our vulnerability tracking tool and consider other vulnerability management tools and techniques to further enhance our security performance.<br>
In summary, we use a vulnerability tracking tool to monitor vulnerability fix progress and regularly review our vulnerability fix process, making necessary improvements to ensure timely fixes and improve our security performance.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题二十二：</div>
                <div class="q">How do you address code vulnerabilities identified in the development lifecycle and during runtime?<br>您如何解决在开发生命周期和运行时发现的代码漏洞？</div>
                <div class="a">我们致力于确保我们的应用程序在开发生命周期和运行时都不会出现漏洞。为此，我们采取了以下措施来解决在开发生命周期和运行时发现的代码漏洞：<br>
1、使用静态和动态代码分析工具：我们使用静态和动态代码分析工具来发现和修复漏洞。静态代码分析工具用于分析代码库中的代码，而动态代码分析工具用于分析应用程序的运行时行为。<br>
2、实施安全代码审查流程：我们实施了安全代码审查流程，以确保所有代码都经过审查，并且没有安全漏洞。我们的开发团队会对每个代码提交进行审查，并确保在合并到主分支之前修复所有漏洞。<br>
3、采用持续集成和持续交付：我们采用持续集成和持续交付流程来确保代码质量和安全性。每次代码提交后，我们会进行自动化测试和集成测试，以确保代码的正确性和安全性。在代码推送到生产环境之前，我们会进行额外的测试和审查。<br>
4、实施安全漏洞管理计划：我们实施了安全漏洞管理计划，以处理在开发生命周期和运行时发现的漏洞。我们采用漏洞跟踪工具来跟踪漏洞修复的进度，并确保漏洞得到及时修复。<br>
5、不断更新和修复漏洞：我们不断更新和修复漏洞，以确保我们的应用程序保持安全性。我们定期对代码库进行漏洞扫描，并使用漏洞数据库来跟踪已知漏洞并及时修复。<br>
通过这些措施，我们相信我们能够保证我们的应用程序在开发生命周期和运行时都是安全和可靠的。<br>
<br>
We are committed to ensuring that our applications are free from vulnerabilities during both the development lifecycle and runtime. To achieve this, we have implemented the following measures to address code vulnerabilities discovered during the development lifecycle and at runtime:<br>
1、Use of static and dynamic code analysis tools: We utilize static and dynamic code analysis tools to identify and fix vulnerabilities. Static code analysis tools are used to analyze code in the codebase, while dynamic code analysis tools are used to analyze the runtime behavior of the application.<br>
2、Implementation of a secure code review process: We have implemented a secure code review process to ensure that all code is reviewed and free of security vulnerabilities. Our development team conducts a review of each code submission and ensures that all vulnerabilities are fixed before merging to the main branch.<br>
3、Adoption of continuous integration and continuous delivery: We utilize continuous integration and continuous delivery processes to ensure code quality and security. After each code submission, we perform automated testing and integration testing to ensure code correctness and security. Before pushing code to the production environment, we conduct additional testing and review.<br>
4、Implementation of a security vulnerability management plan: We have implemented a security vulnerability management plan to handle vulnerabilities discovered during the development lifecycle and runtime. We use a vulnerability tracking tool to track the progress of vulnerability fixes and ensure that vulnerabilities are fixed promptly.<br>
5、Continuous updating and vulnerability fixing: We continuously update and fix vulnerabilities to ensure that our applications remain secure. We perform regular vulnerability scans on our codebase and use vulnerability databases to track known vulnerabilities and fix them promptly.<br>
Through these measures, we believe that we can ensure that our applications are safe and reliable during both the development lifecycle and runtime.</div>
            </div>
            <div class="qaitem">
                <div class="title">问题二十三：</div>
                <div class="q">Who is responsible for change management and how is their access granted? Please specify job title.<br>谁负责变更管理以及如何授予他们访问权限？请注明职务。</div>
                <div class="a" style="border-bottom: 1px solid #333;">我们的 CTO 负责监督应用程序的任何更改，并确保这些更改符合亚马逊 SP-API 访问要求。我们采取基于需要知道原则授予访问权限，只有经过授权的人员才能对应用程序进行任何修改。我们的 CTO 致力于维护高水平的安全性和合规性，并定期审查变更管理流程，以确保它们符合最<br>
我们还会定期对与个人身份信息交互的应用程序和网络组件（包括硬件）进行例行检查，例如漏洞扫描和渗透测试，以确保其安全性。在将应用程序更改推向生产之前，我们也会在专用测试环境中对其进行评估。我们还会在每次发布之前扫描应用程序代码以查找漏洞。我们拥有正式的变更管理流程来定义测试、验证和批准变更的职责，并限制可以执行这些操作的人员的访问权限。<br>
对于临时授权，我们会进行仔细审核，并确保只有经过授权的人员才能获得所需的权限。这些权限通常会在任务完成后自动回收，并在 1 天内从系统中删除。同时，我们定期审查我们的安全日志，并设置了预警提示，以及其他安全措施，以及为应对安全事件而采取的响应计划。我们的安全措施旨在确保我们的系统和数据受到充分保护。<br>
<br>
Our CTO is responsible for overseeing any changes to the application and ensuring that these changes comply with Amazon's SP-API access requirements. We grant access based on the principle of least privilege, so only authorized personnel can make any modifications to the application. Our CTO is committed to maintaining high levels of security and compliance, and regularly reviews the change management process to ensure that it meets the latest standards.<br>
We also conduct routine checks on the applications and network components (including hardware) that interact with personal identity information, such as vulnerability scans and penetration testing, to ensure their security. Before pushing application changes to production, we evaluate them in a dedicated testing environment. We also scan the application code for vulnerabilities before each release. We have a formal change management process to define the responsibilities of testing, validating, and approving changes, and restrict access to personnel who can perform these operations.<br>
For temporary authorizations, we carefully review and ensure that only authorized personnel are granted the necessary permissions. These permissions are usually automatically revoked after the task is completed and are removed from the system within 1 day. Meanwhile, we regularly review our security logs and have set up alert triggers, as well as other security measures and response plans to address security incidents. Our security measures are designed to ensure that our systems and data are fully protected.</div>
            </div>
        </div> -->
    </div>
</div>
</template>
  
<script>
 export default {
    setup(){
        const concealText = `
数据保护政策 (“DPP”) 管理信息的接收、存储、使用、传输和处置，包括通过亚马逊服务 API（包括商城网络服务 API）出售和检索的数据。本政策适用于存储、处理或以其他方式处理从亚马逊服务 API 出售和检索的数据的所有系统。本政策是对亚马逊服务 API 开发者协议和可接受使用政策的补充。不遵守可能会导致暂停或终止亚马逊服务 API 访问。

1. 一般安全要求

与行业领先的安全性一致，开发​​人员将维护物理、管理和技术保护措施以及其他安全措施 (i) 以维护开发人员访问、收集、使用、存储或传输的信息的安全性和机密性，以及 (ii) ) 保护该信息免受已知或合理预期的威胁或对其安全性和完整性的危害、意外丢失、更改、披露和所有其他非法形式的处理。但不限于，开发商将遵守以下要求： 

1.1 网络保护。 开发人员必须实施网络保护控制，包括网络防火墙和网络访问控制列表，以拒绝访问未经授权的 IP 地址。开发人员必须在最终用户设备上实施网络分段、防病毒和反恶意软件。开发人员必须将公共访问权限仅限于获得批准的用户，并为每个具有系统访问权限的人进行数据保护和 IT 安全培训。 

1.2 访问管理。开发人员必须建立正式的用户访问注册流程，通过确保为每个可以通过计算机访问信息的人分配一个唯一的 ID，为所有用户类型和服务分配访问权限。开发人员不得创建或使用通用的、共享的或默认的登录凭据或用户帐户，并防止共享用户帐户。开发人员必须实施基线机制以确保始终只有所需的用户帐户才能访问信息。开发人员必须限制员工和承包商在个人设备上存储信息。开发人员将通过检测异常使用模式和登录尝试并禁用具有信息访问权限的帐户来维护和实施“帐户锁定”。开发人员必须至少每季度审查一次有权访问信息的人员和服务列表。开发人员必须确保在 24 小时内禁用和/或删除离职员工的访问权限。 

1.3 最小权限原则。开发人员必须实施细粒度的访问控制机制，以允许按照最小特权原则向使用应用程序的任何一方和应用程序的授权操作员授予权限。必须在“需要知道”的基础上授予对信息的访问权限。 

1.4 凭证管理。开发人员必须为有权访问信息的人员和系统建立最低密码要求。密码要求必须至少为十二 (12) 个字符，不包括用户名的任何部分、大写字母、小写字母、数字和特殊字符的组合，包括每个字符的最低要求。开发人员必须为所有用户建立最短 1 天的密码有效期和最长 365 天的密码有效期。开发人员必须确保所有用户帐户都需要多重身份验证 (MFA) 开发人员必须确保亚马逊提供的 API 密钥已加密，并且只有要求的员工才能访问它们。

1.5 传输加密。开发人员必须使用 TLS 1.2+、SFTP 和 SSH-2 等安全协议对传输中的所有信息进行加密。开发人员必须对所有适用的内部和外部端点执行此安全控制。开发人员必须使用数据消息级加密，其中通道加密（例如，使用 TLS）终止于不受信任的多租户硬件（例如，不受信任的代理）。 

1.6 风险管理和事件响应计划。. 开发商必须有风险评估和管理流程，每年由开发商的高级管理层审查，其中包括但不限于评估潜在威胁和漏洞以及可能性和影响，以跟踪已知风险。开发人员必须创建和维护计划和/或操作手册来检测和处理安全事件。此类计划必须确定事件响应角色和职责，定义可能影响亚马逊的事件类型，为定义的事件类型定义事件响应程序，并定义升级路径和程序以将安全事件升级到亚马逊。开发人员必须每六 (6) 个月以及在发生任何重大基础设施或系统更改（包括对系统、控制、操作环境、风险级别、和供应链。开发人员必须在检测到安全事件后的 24 小时内通知亚马逊（通过电子邮件发送至 3p-security@amazon.com）。开发商全权负责按照当地适用法律的要求通知相关政府或监管机构。开发人员必须调查每个安全事件，并记录事件描述、补救措施以及为防止未来再次发生而实施的相关纠正过程/系统控制。开发人员必须维护收集的所有证据或记录的监管链，并且必须根据要求（如果适用）向亚马逊提供此类文件。如果发生安全事件，除非亚马逊明确书面要求开发者这样做，否则开发者不能代表亚马逊或代表亚马逊向任何监管机构或客户发言。

1.7 删除请求。开发人员必须根据亚马逊要求在收到亚马逊请求后 30 天内删除的通知，永久安全地删除信息，除非该数据是满足法律要求（包括税收或监管要求）所必需的。安全删除必须按照行业标准清理流程（例如 NIST 800-88）进行。开发人员还必须在亚马逊发出通知 90 天后永久安全地删除所有实时（在线或网络可访问）信息实例。如果亚马逊要求，开发商将书面证明所有信息均已安全销毁。 

1.8 数据归属。 开发人员必须将信息存储在单独的数据库中，或者实施一种机制来标记和识别包含信息的任何数据库中所有数据的来源。 

2. 特定于个人身份信息的附加安全要求

个人身份信息 (“PII”) 必须满足以下附加安全要求。PII 被授予开发人员用于特定的税收和商家履行的运输目的，在必备的基础上。如果亚马逊服务 API 包含 PII，或者 PII 与非 PII 组合，则整个数据存储必须符合以下要求： 

2.1 数据保留。 开发人员将在订单交付后保留 PII 不超过 30 天，并且仅出于以下目的且在必要时保留 PII：(i) 履行订单，(ii) 计算和缴纳税款，(iii) 出具税务发票和其他合法的所需的文件，以及 (iv) 满足法律要求，包括税收或监管要求。只有在法律要求且仅出于遵守该法律的目的时，开发商才可以在订单交付后保留数据 30 天以上。根据第 1.5 节（“传输中加密”）和第 2.4 节（“静态加密”），PII 在任何时候都不应不受保护地传输或存储。

2.2 数据治理。开发人员必须为其应用程序或服务创建、记录和遵守隐私和数据处理及分类策略，这些策略管理在管理和保护信息资产时应用的适当行为和技术控制。应保留数据处理活动的记录，例如特定数据字段以及它们如何为所有 PII 收集、处理、存储、使用、共享和处置，以建立问责制和合规性。开发人员必须建立一个流程来检测并遵守适用于其业务的隐私和安全法律和法规要求，并保留其合规性的书面证据。开发人员必须建立并遵守其隐私政策，以获得客户同意和数据访问、纠正、删除、或在适用或数据隐私法规要求的情况下停止共享/处理他们的信息。开发人员必须具备适当的技术和组织流程和系统，以协助授权用户处理数据主体访问请求。开发人员必须在与处理 PII 的员工签订的雇佣合同中包含合同条款，以维护 PII 的机密性。

2.3 资产管理。开发人员必须维护信息系统的基线标准配置，并保留可访问 PII 的软件和物理资产（例如计算机、移动设备）的清单，并每季度更新一次。存储、处理或以其他方式处理 PII 的实物资产必须遵守本政策中规定的所有要求。开发人员不得将 PII 存储在可移动媒体、个人设备或不安全的公共云应用程序（例如，通过 Google Drive 提供的公共链接）中，除非至少使用 AES-128 或  RSA-2048 位密钥或更高位密钥对其进行加密。开发人员必须安全地处理任何包含 PII 的打印文档。开发人员必须实施适当的数据丢失防护 (DLP) 控制，以监视和检测未经授权的数据移动。 

2.4 静态加密。 开发人员必须至少使用密钥大小为 2048 位或更高的 AES-128 或 RSA 加密所有静态 PII。用于加密静态 PII 的加密材料（例如，加密/解密密钥）和加密功能（例如，实现虚拟可信平台模块和提供加密/解密 API 的守护程序）必须只能由开发人员的流程和服务访问。 

2.5 安全编码实践。开发人员不得在其代码中硬编码敏感凭证，包括加密密钥、秘密访问密钥或密码。不得在公共代码存储库中公开敏感凭证。开发人员必须维护单独的测试和生产环境。 

2.6 记录和监控。开发人员必须收集日志以检测其应用程序和系统的安全相关事件，包括事件的成功或失败、日期和时间、访问尝试、数据更改和系统错误。开发人员必须在提供信息访问的所有渠道（例如，服务 API、存储层 API、管理仪表板）上实施此日志记录机制。开发人员必须实时（例如 SIEM 工具）或每两周审查一次日志。所有日志都必须具有访问控制，以防止在其整个生命周期内进行任何未经授权的访问和篡改。日志不得包含 PII，除非 PII 是满足法律要求（包括税收或监管要求）所必需的。除非适用法律另有要求，否则日志必须至少保留 90 天，以供在发生安全事件时参考。开发人员必须建立机制来监控日志和所有系统活动，以触发对可疑行为的调查警报（例如，多次未授权调用、意外请求率和数据检索量以及对金丝雀数据记录的访问）。开发人员必须实施监控警报和流程，以检测信息是否从其受保护边界提取或是否可以在其受保护边界之外找到。开发人员应在触发监控警报时进行调查，这应记录在开发人员的事件响应计划中。开发人员必须实施监控警报和流程，以检测信息是否从其受保护边界提取或是否可以在其受保护边界之外找到。开发人员应在触发监控警报时进行调查，这应记录在开发人员的事件响应计划中。开发人员必须实施监控警报和流程，以检测信息是否从其受保护边界提取或是否可以在其受保护边界之外找到。开发人员应在触发监控警报时进行调查，这应记录在开发人员的事件响应计划中。 

2.7 漏洞管理。开发人员必须创建和维护计划和/或运行手册来检测和修复漏洞。开发人员必须通过执行漏洞扫描和适当修复来保护包含 PII 的物理硬件免受技术漏洞的影响。开发人员必须至少每 180 天进行一次漏洞扫描，至少每 365 天进行一次渗透测试，并在每次发布之前扫描代码以查找漏洞。此外，开发人员必须通过测试、验证更改、批准更改以及限制对可以执行这些操作的人员的访问来控制对存储硬件的更改。开发人员必须制定适当的程序和计划，以便在发生物理或技术事件时及时恢复对 PII 的可用性和访问。

3. 审核与评估

开发人员必须在本协议期间及之后的 12 个月内保留所有合理需要的适当账簿和记录，以验证是否遵守可接受的使用政策、数据保护政策和亚马逊服务 API 开发人员协议。根据亚马逊的书面请求，开发人员必须以书面形式向亚马逊证明他们遵守这些政策。 

根据要求，亚马逊可以或可以让亚马逊选择的独立注册会计师事务所审计、评估和检查与开发人员的应用程序在检索、存储过程中涉及的所有系统的账簿、记录、设施、操作和安全性，或信息处理。亚马逊将对开发者作为此审计、评估或检查的一部分披露的任何非公开信息保密，这些信息被指定为机密信息，或者鉴于信息的性质或其披露的相关情况，合理地应被视为机密信息。开发人员必须就可能在开发人员设施和/或分包商设施进行的审计或评估与亚马逊或亚马逊审计员合作。如果审计或评估发现缺陷，违反和/或未能遵守我们的条款、条件或政策，开发商必须自行承担成本和费用，并采取一切必要行动在商定的时间范围内补救这些缺陷。根据要求，开发商必须以亚马逊要求的形式提供补救证据（可能包括政策、文件、屏幕截图或应用程序或基础设施变更的屏幕共享），并在审计结束前获得亚马逊对提交证据的书面批准。 

4.定义

“亚马逊服务 API” 是指亚马逊为帮助亚马逊授权用户以编程方式交换数据而提供的任何应用程序编程接口 (API)。 

“API 材料” 是指我们提供的与亚马逊服务 API 相关的材料，包括 API、文档、规范、软件库、软件开发工具包和其他支持材料，无论格式如何。

“应用程序” 是指与亚马逊服务 API 或 API 材料交互的软件应用程序或网站。

“授权用户 是指经亚马逊特别授权使用适用系统或服务的亚马逊系统或服务用户。

“客户” 是指从亚马逊面向公众的网站购买商品或服务的任何个人或实体。

“开发人员” 是指代表授权用户将亚马逊服务 API 或 API 材料用于许可用途的任何个人或实体（包括您，如果适用）。

“信息” 是指通过亚马逊服务 API、亚马逊门户或亚马逊面向公众的网站公开的任何信息。此数据可以是公开的或非公开的，包括有关亚马逊客户的个人身份信息。

“个人身份信息”（“PII”） 是指可单独使用或与其他信息一起用于识别、联系、在上下文中识别或定位亚马逊客户或授权用户的信息。这包括但不限于客户或授权用户的姓名、地址、电子邮件地址、电话号码、礼品信息内容、调查回复、付款详情、购买、cookie、数字指纹（例如浏览器、用户设备） 、IP 地址、地理位置、九位邮政编码或联网设备产品标识符。

“安全事件” 是指任何实际或疑似未经授权的访问、收集、获取、使用、传输、披露、损坏或丢失信息，或破坏包含信息的任何环境。`
        return {
            concealText
        }
    }
  }
</script>

<style lang="less" scoped>
    .conceal_text {
        line-height: 30px;
        margin-top:100px;
        text-align: left;
        white-space: Pre-wrap;
        margin-bottom: 30px;
        .table_1 {
                border: 1px solid #333;
                border-collapse: collapse;
            td, th {
                border: 1px solid #333;
                border-collapse: collapse;
            }
        }
    }
</style>