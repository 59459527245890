<template>
  <div class="index">
      <div class="body">
          <div class="amazon">
            <div class="global-width">
                <div class="service">
                    <div class="title">
                        为什么选择我们?<br>WHY US?
                    </div>
                    <div class="item">
                        <div class="title">简化应用开发</div>
                        <span>借助 Zoho 的低代码拖放式开发平台完成更多任务。Zoho Creator 将直观的 UI 与指导性脚本相结合，帮助您轻松开发各种应用实现目的，无论是从报表中获取信息还是自动化复杂的工作流。</span>
                        <picture><source type="image/webp" srcset="https://www.zoho.com.cn//sites/default/files/creator/why-simplified.webp"><img data-lazy="" class="main-img data-loaded" src="https://www.zoho.com.cn//sites/default/files/creator/why-simplified.png" alt="简化" width="300" height="90"></picture>
                    </div>
                    <div class="item">
                        <div class="title">降低 IT 复杂性和成本</div>
                        <span>高效创建应用、发布更新和添加新功能，告别耗时的传统开发流程。Creator 的一大优势是大幅节省服务器监控和维护等方面的开支。</span>
                        <picture><source type="image/webp" srcset="https://www.zoho.com.cn//sites/default/files/creator/why-reduced.webp"><img data-lazy="" class="main-img data-loaded" src="https://www.zoho.com.cn//sites/default/files/creator/why-reduced.png" alt="IT 复杂性和成本" width="300" height="90"></picture>
                    </div>
                    <div class="item">
                        <div class="title">广泛的第三方集成</div>
                        <span>Creator 提供预构建的集成和 REST API，帮助您连接现有的新旧软件系统。通过增强（而不是替换）仍适用于贵公司的旧系统，简化数字化转型。</span>
                        <picture><source type="image/webp" srcset="https://www.zoho.com.cn//sites/default/files/creator/why-extensive-third-party.webp"><img data-lazy="" class="main-img data-loaded" src="https://www.zoho.com.cn//sites/default/files/creator/why-extensive-third-party.png" alt="集成" width="300" height="90"></picture>
                    </div>
                    <div class="item" style="padding-right: 0">
                        <div class="title">致力维护数据安全</div>
                        <span>为了遵守全球标准和行业法规，Zoho Creator 提供全面的安全功能，例如审计日志、用户访问控制和自动化威胁评估。</span>
                        <picture><source type="image/webp" srcset="https://www.zoho.com.cn//sites/default/files/creator/why-data-security.webp"><img data-lazy="" class="main-img data-loaded" src="https://www.zoho.com.cn//sites/default/files/creator/why-data-security.png" alt="数据安全性" width="300" height="90"></picture>
                    </div>

                    <div class="item">
                        <div class="title">经过精心设计和优化，性能可靠</div>
                        <span>无论您是构建简单的服务工单应用还是复杂的 ERP，我们都能助您一臂之力。Creator 保证 99.9% 正常运行时间，确保重要业务运营不会在关键时刻中断。</span>
                        <picture><source type="image/webp" srcset="https://www.zoho.com.cn//sites/default/files/creator/why-engineered.webp"><img data-lazy="" class="main-img data-loaded" src="https://www.zoho.com.cn//sites/default/files/creator/why-engineered.png" alt="经过精心设计和优化" width="300" height="90"></picture>
                    </div>
                    <div class="item">
                        <div class="title">灵活的价格方案 - 没有隐性成本</div>
                        <span>Zoho Creator 提供灵活的价格方案，支持各种规模的机构。选择最适合贵公司的方案，按月或按年付款。</span>
                        <picture><source type="image/webp" srcset="https://www.zoho.com.cn//sites/default/files/creator/why-flexible.svg"><img data-lazy="" class="main-img data-loaded" src="https://www.zoho.com.cn//sites/default/files/creator/why-flexible.svg" alt="查看价格方案" width="300" height="90"></picture>
                    </div>
                    <!-- <div class="item">
                        <div class="title">账单对账核算</div>
                        结合广告调价策略方法，帮助卖家进行策略管理，及自动进行广告及策略调价管理。
                    </div>
                    <div class="item">
                        <div class="title">广告数据分析</div>
                        帮助卖家，通过多维度自定义进行广告数据分析，帮助卖家进准把控投产比。
                    </div> -->
                </div>
            </div>
          </div>
          <div class="power">
              <div class="title">提供三项强大的技术支撑能力，让您更专注于业务方案设计与开发！</div>
              <ul>
                  <li><img src="../assets/img/seting.png"></li>
                  <li><img src="../assets/img/dev.png"></li>
                  <li><img src="../assets/img/big-data.png"></li>
              </ul>
          </div>
          <div class="totest">
              <div class="global-width">
                <div class="title">开启企业级软件系统新时代</div>
                <!-- <div class="stitle">开启企业级软件系统新时代</div> -->
                <a class="to" href="javascript:void(0)" @click="building()">申请首测</a>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Index',
  setup() {
  }
}
</script>

<style lang="less" scoped>
    .index {
        .body {
            .amazon {
                .manager {
                        text-align: left;
                    .item {
                        display: inline-block;
                        width: 224px;
                        padding-right: 96px;
                        font-size: 16px;
                        .title {
                            font-size: 20px;
                            color: #338EC7;
                            font-weight: 500;
                            padding-bottom: 15px;
                        }
                    }
                    .item:last-child {
                        padding-right: 0px;
                    }
                    &>.title {
                        font-size: 28px;
                        text-align: center;
                        padding-bottom: 34px;
                    }
                }
                .service {
                    text-align: left;
                    margin-top: 150px;
                    margin-bottom: 50px;
                    .item {
                        padding-top: 50px;
                        display: inline-block;
                        width: 380px;
                        padding-right: 20px;
                        font-size: 16px;
                        vertical-align: top;;
                        span {
                            display: block;
                            padding-bottom: 20px;
                        }
                        .title {
                            font-size: 20px;
                            font-weight: 500;
                            padding-bottom: 15px;
                        }
                    }
                    .item:last-child {
                        padding-right: 0px;
                    }
                    &>.title {
                        font-size: 28px;
                        text-align: center;
                    }
                }
            }
            .duibi {
                img {
                    margin-top:50px;
                    margin-bottom: 60px;
                }
            }
            .power {
                background: #F4FAFF;
                padding-top:50px;
                .title {
                    font-size: 32px;
                    padding-bottom:65px;
                }
                ul {
                    margin:0 auto;
                    padding-bottom: 120px;
                    li {
                        width:330px;
                        text-align: center;
                        img {
                            
                        }
                    }
                }
            }
            .totest {
                background-image: url('../assets/img/bg-1.png');
                height: 150px;
                margin:0 auto;
                text-align: left;
                .global-width {
                    position: relative;
                }
                .title {
                    padding-top:52px;
                    font-size: 32px;
                    color: #fff;
                }
                .stitle {
                    padding-top:8px;
                    font-size: 18px;
                    color: #fff;
                }
                .to {
                    width:160px;
                    height: 48px;
                    line-height: 48px;
                    color: #338EC7;
                    background-color: #fff;
                    display: block;
                    position: absolute;
                    top: 50px;
                    right: 0px;
                    text-align: center;
                    font-size: 20px;
                }
            }
        }
    }
    .clear {
        clear: both;
    }
</style>