<template>
    <div class="index">
        <Header></Header>
        <router-view/>
        <Footer></Footer>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from './header.vue'
import Footer from './footer.vue'

export default {
  name: 'Index',
  components: {
    Header,
    Footer
},
  setup() {
    const building = () => {
        // ElMessage({
        //     message: '敬请期待！',
        // })
        window.location.href='/details'
    }
    const building1 = () => {
        window.location.href="/pri"
    }
    const building2 = () => {
        window.location.href="/sup"
    }
    const building3 = () => {
        window.location.href="/buy"
    }
    const login = () => {
        location.href="https://chiefsow.app.sxzxg.com/"
    }
    return {
        building,
        building1,
        building2,
        building3,
        login
    }
  }
}
</script>

<style lang="less" scoped>
    .index {
        .header {
            height: 80px;
            background: #1D254E;
            position: fixed;
            z-index: 100;
            width:100%;
            top:0;
            .global-width1 {
                margin:0 auto;
            }
            .logo {
                float: left;
                img {
                    width: 348px;
                    margin-top:17px;
                    margin-left: 47px;
                }
            }
            .menu {
                line-height: 80px;
                float:right;
                margin-right:140px;
                ul {
                    li {
                        font-size: 16px;
                        color: #FFFFFF;
                        margin-right:40px;
                        a {
                            color:#fff;
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
            .login {
                float:right;
                width:52px;
                height: 28px;
                line-height: 28px;
                background-color: #fff;
                border-radius: 4px;
                display: block;
                text-align: center;
                margin-top:26px;
                margin-right:30px;
            }
        }
        .banner {
            margin-top:80px;
            background: #F1F1F1;
            position: relative;
            height: 440px;
            :deep(.el-carousel) {
                width:100%;
                margin:0 auto;
            }
            h1 {
                line-height: 400px;
                font-size: 45px;
            }
        }
        .body {
            .amazon {
                .manager {
                        text-align: left;
                    .item {
                        display: inline-block;
                        width: 224px;
                        padding-right: 96px;
                        font-size: 16px;
                        .title {
                            font-size: 20px;
                            color: #338EC7;
                            font-weight: 500;
                            padding-bottom: 15px;
                        }
                    }
                    .item:last-child {
                        padding-right: 0px;
                    }
                    &>.title {
                        font-size: 28px;
                        text-align: center;
                        padding-bottom: 34px;
                    }
                }
                .service {
                    text-align: left;
                    margin-top: 150px;
                    margin-bottom: 50px;
                    .item {
                        padding-top: 50px;
                        display: inline-block;
                        width: 224px;
                        padding-right: 96px;
                        font-size: 16px;
                        .title {
                            font-size: 20px;
                            font-weight: 500;
                            padding-bottom: 15px;
                        }
                    }
                    .item:last-child {
                        padding-right: 0px;
                    }
                    &>.title {
                        font-size: 28px;
                        text-align: center;
                    }
                }
            }
            .duibi {
                img {
                    margin-top:50px;
                    margin-bottom: 60px;
                }
            }
            .power {
                background: #F4FAFF;
                padding-top:50px;
                .title {
                    font-size: 32px;
                    padding-bottom:65px;
                }
                ul {
                    margin:0 auto;
                    padding-bottom: 120px;
                    li {
                        width:330px;
                        text-align: center;
                        img {
                            
                        }
                    }
                }
            }
            .totest {
                background-image: url('../assets/img/bg-1.png');
                height: 150px;
                margin:0 auto;
                text-align: left;
                .global-width {
                    position: relative;
                }
                .title {
                    padding-top:52px;
                    font-size: 32px;
                    color: #fff;
                }
                .stitle {
                    padding-top:8px;
                    font-size: 18px;
                    color: #fff;
                }
                .to {
                    width:160px;
                    height: 48px;
                    line-height: 48px;
                    color: #338EC7;
                    background-color: #fff;
                    display: block;
                    position: absolute;
                    top: 50px;
                    right: 0px;
                    text-align: center;
                    font-size: 20px;
                }
            }
        }
        .footer {
            background-color: #383E45;
            color: #fff;
            clear: both;
            .helper {
                float: left;
                padding-top: 50px;
                text-align: left;
                .quick-menu {
                    float: left;
                    .column {
                        width: 224px;
                        text-align: left;
                        float: left;
                        .title {
                            font-size: 16px;
                            padding-bottom:10px;
                        }
                        ul {
                            li {
                                display: block;
                                a {
                                color: #fff;
                                opacity: 0.7;
                                    line-height: 28px;
                                    &:hover {
                                        opacity: 0.9;
                                    }
                                }
                            }
                        }
                    }
                }
                .lang {
                    float:left;
                    ul {
                        li {
                            display: block;
                            a {
                                color: #fff;
                                opacity: 0.7;
                                line-height: 28px;
                                &:hover {
                                    opacity: 0.9;
                                }
                            }
                        }
                    }
                }
            }
            .more {
                padding-top: 30px;
                padding-bottom: 30px;
                border-top: 1px solid #aaa;
                margin-bottom: 50px;
                color: #fff;
                opacity: 0.7;
                a {
                    color: #fff;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
    .clear {
        clear: both;
    }
</style>