<template>
    <div class="index">
        <div class="body">
            <div class="amazon">
                <div class="global-width">
                    <div class="service">
                        <div class="title" style="font-weight: 800;">
                            版本对比
                            <br>
                            <p style="font-size: 14px;color: #9f9999;">（每个版本均包含全系统功能与客户服务，有效提升运营管理效率）</p> 
                        </div>
                        <div class="title" style="width: 50%; margin-left: 25%; font-weight: 800; display: flex;justify-content: space-between;">
                            <div>
                                体验版<br>
                                <p style="font-size: 18px;">适合测试体验</p>
                            </div>
                            <div>
                                套餐版<br>
                                <p style="font-size: 18px;">适合中大型企业</p>
                            </div>
                        </div>
                        <div class="title" style="margin-top: 50px;margin-left: 10%;width: 80%;">
                            <el-table :data="tableData">
                                <el-table-column prop="diff" label="差异项"  />
                                <el-table-column prop="free" label="体验套餐" />
                                <el-table-column prop="lowLevel" label="基础套餐" width="180" />
                                <el-table-column prop="groupLevel" label="成长套餐" width="180"/>
                                <el-table-column prop="midLevel" label="中型规模套餐" width="180"/>
                            </el-table>
                        </div>
                        <div class="title" style="margin-top: 50px">
                            <p style="font-weight: 800;">专业的服务优势</p>
                            <div style="margin-left: 5%; width: 90%; display: flex;justify-content: space-around; margin-top: 50px;">
                                <div>
                                    <img src="../assets/img/合同有效期.svg" alt="180天" style="width: 120px;">
                                    <p style="margin-top: 30px; font-size: 16px; color: black;">180天</p>
                                    <p style="font-size: 16px;">支持180天不满意100%全额退款</p>
                                </div>
                                <div>
                                    <img src="../assets/img/一体化.svg" alt="一体化服务" style="width: 120px;">
                                    <p style="margin-top: 30px; font-size: 16px; color: black;">一体化服务</p>
                                    <p style="font-size: 16px;">顾问、实施、售后全程服务</p>
                                </div>
                                <div>
                                    <img src="../assets/img/更快捷.svg" alt="快捷" style="width: 120px;">
                                    <p style="margin-top: 30px; font-size: 16px; color: black;">快捷</p>
                                    <p style="font-size: 16px;">7*12小时、实时响应、沟通无碍</p>
                                </div>
                                <div>
                                    <img src="../assets/img/专业高效.svg" alt="高效" style="width: 120px;">
                                    <p style="margin-top: 30px; font-size: 16px; color: black;">高效</p>
                                    <p style="font-size: 16px;">流程简洁、无缝衔接、快速解决</p>
                                </div>
                            </div>
                        </div>
                        <div class="title" style="margin-top: 50px">
                            <p style="font-weight: 800;">常见问题</p>
                            <div style="margin-left: 5%; width: 90%; display: flex;justify-content: space-around; margin-top: 40px; flex-wrap: wrap;">
                                <div v-for="item in quesition" style="width: 48%;">
                                    <el-card class="box-card" style="height: 200px;margin-top: 20px;">
                                        <template #header>
                                            <div class="card-header" style="font-size: 16px;display: flex;justify-content: flex-start;">
                                                <span>{{ item.title }}</span>
                                            </div>
                                        </template>
                                        <p style="font-size: 14px;color: #999999;">{{ item.content }}</p>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                        <div class="title" style="width: 80%;margin-left: 10%; margin-top: 40px;display: flex;justify-content: space-between;align-items: center;">
                            <!-- <div>
                                <p style="font-size: 24px; font-weight: 600;">立即体验</p>
                                <p style="font-size: 18px; font-weight: 400;">零门槛迈入企业成长新阶段</p>
                            </div> -->
                            <!-- <div>
                                <el-button type="primary">免费试用</el-button>
                                <el-button type="primary">预约演示</el-button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="power">
                <div class="title">提供三项强大的技术支撑能力，让您更专注于业务方案设计与开发！</div>
                <ul>
                    <li><img src="../assets/img/seting.png"></li>
                    <li><img src="../assets/img/dev.png"></li>
                    <li><img src="../assets/img/big-data.png"></li>
                </ul>
            </div>
            <div class="totest">
                <div class="global-width">
                  <div class="title">开启企业级软件系统新时代</div>
                  <!-- <div class="stitle">开启企业级软件系统新时代</div> -->
                  <a class="to" href="javascript:void(0)" @click="building()">申请首测</a>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import { ElButton,ElTable,ElTableColumn,ElCard } from 'element-plus';
  export default {
    name: 'Index',
    components:{
        ElButton,
        ElTable,
        ElTableColumn,
        ElCard
    },
    setup() {
        const tableData = [
            {
                diff:'总价',
                free: '¥0',
                lowLevel: '¥49,999.00',
                groupLevel: '¥99,999.00',
                midLevel: '¥199,999.00'
            },
            {
                diff:'单量',
                free: '1000单/月',
                lowLevel: '200万单',
                groupLevel: '500万单',
                midLevel: '1000万单'
            },
            {
                diff:'单价',
                free: '¥0',
                lowLevel: '¥136/天',
                groupLevel: '¥273/天',
                midLevel: '¥547/天'
            },
            {
                diff:'业务许可人数',
                free: '5（人）',
                lowLevel: '100（人）',
                groupLevel: '300（人）',
                midLevel: '700（人）'
            },
            {
                diff:'业务数据单元存储容量',
                free: '2（GB）',
                lowLevel: '50（GB）',
                groupLevel: '300（GB）',
                midLevel: '1（TB）'
            },
            {
                diff:'文件数据单元存储容量',
                free: '5（GB）',
                lowLevel: '100（GB）',
                groupLevel: '1（TB）',
                midLevel: '2（TB）'
            },
            {
                diff:'大数据运算线程数',
                free: '0（个）',
                lowLevel: '5（个）',
                groupLevel: '10（个）',
                midLevel: '20（个）'
            },
            {
                diff:'沙盒环境数量/刷新次数',
                free: '0（个）/0（次）',
                lowLevel: '0（个）/0（次）',
                groupLevel: '1（个）/2（次）',
                midLevel: '1（个）/5（次）'
            },
            {
                diff:'开放接口授权数',
                free: '1（个）',
                lowLevel: '1000（个）',
                groupLevel: '4000（个）',
                midLevel: '10000（个）'
            },
            {
                diff:'软件部署形式',
                free: '云部署',
                lowLevel: '云部署',
                groupLevel: '云部署',
                midLevel: '云部署'
            },
            {
                diff:'订单有效期',
                free: '---',
                lowLevel: '',
                groupLevel: '签合同起一年内',
                midLevel: ''
            },
            {
                diff:'售后保障',
                free: '---',
                lowLevel: '',
                groupLevel: '支持半年不满意100%全额退款',
                midLevel: ''
            },
        ]
        const quesition = [
            {
                title: '您好，请问我司⾃主开发的代码可以申请软件著作吗？',
                content: '尊敬的⽤户，您好，贵司所开发的代码属于“业务代码”，是可以正常进⾏软件著作申请的，并且平台会保护您的代码产权，未来可以在平台进⾏交易销售给其他企业⽤户。'
            },
            {
                title: '您好，请问是否⽀持私有化部署？',
                content: '尊敬的⽤户，您好，⽹络运维环境需要专业⼈员、设备、信息安全保障及相关技术条件，私有化部署不仅增加企业成本，更是考验企业技术⽔平，故此，平台提倡且⽀持企业进⾏SAAS化开箱即⽤部署，相关环境运维及信息安全可由我司相关技术专家为贵司保驾护航。'
            },
            {
                title: '可以前期购买你们的开发服务，后期⾃⾏招程序员承接吗？',
                content: '尊敬的⽤户，您好，平台独特的系统设计使得业务代码⾼度分离，不管前期由谁负责开发，后来者只要具备2-3年JS语⾔的开发经验，即可做到⽆缝衔接。'
            },
            {
                title: '如何购买首席软件官系统？',
                content: `您可以选择以下任意一种方式：
                            ①点击“购买”页面，点击“了解价格”按钮即可进行充值操作；
                            ②拨打 173-026-38460 或点击右侧 联系我们 按钮，工作人员将协助您完成充值`
            }
        ]
        return {
            tableData,
            quesition
        }
    }
  }
  </script>
  
  <style lang="less" scoped>
      .index {
          .body {
              .amazon {
                  .manager {
                          text-align: left;
                      .item {
                          display: inline-block;
                          width: 224px;
                          padding-right: 96px;
                          font-size: 16px;
                          .title {
                              font-size: 20px;
                              color: #338EC7;
                              font-weight: 500;
                              padding-bottom: 15px;
                          }
                      }
                      .item:last-child {
                          padding-right: 0px;
                      }
                      &>.title {
                          font-size: 28px;
                          text-align: center;
                          padding-bottom: 34px;
                      }
                  }
                  .service {
                      text-align: left;
                      margin-top: 150px;
                      margin-bottom: 50px;
                      .item {
                          padding-top: 50px;
                          display: inline-block;
                          width: 380px;
                          padding-right: 20px;
                          font-size: 16px;
                          vertical-align: top;;
                          span {
                              display: block;
                              padding-bottom: 20px;
                          }
                          .title {
                              font-size: 20px;
                              font-weight: 500;
                              padding-bottom: 15px;
                          }
                      }
                      .item:last-child {
                          padding-right: 0px;
                      }
                      &>.title {
                          font-size: 28px;
                          text-align: center;
                      }
                  }
              }
              .power {
                  background: #F4FAFF;
                  padding-top:50px;
                  .title {
                      font-size: 32px;
                      padding-bottom:65px;
                  }
                  ul {
                      margin:0 auto;
                      padding-bottom: 120px;
                      li {
                          width:330px;
                          text-align: center;
                          img {
                              
                          }
                      }
                  }
              }
              .totest {
                  background-image: url('../assets/img/bg-1.png');
                  height: 150px;
                  margin:0 auto;
                  text-align: left;
                  .global-width {
                      position: relative;
                  }
                  .title {
                      padding-top:52px;
                      font-size: 32px;
                      color: #fff;
                  }
                  .stitle {
                      padding-top:8px;
                      font-size: 18px;
                      color: #fff;
                  }
                  .to {
                      width:160px;
                      height: 48px;
                      line-height: 48px;
                      color: #338EC7;
                      background-color: #fff;
                      display: block;
                      position: absolute;
                      top: 50px;
                      right: 0px;
                      text-align: center;
                      font-size: 20px;
                  }
              }
          }
          
      }
      .clear {
          clear: both;
      }
      .title :deep(.el-table thead){
            color: #2c3e50;
            font-size: 18px;
        }
      
  </style>