<template>
    <div class="banner">
        <div class="global-width">
            <el-carousel height="440px" >
                <el-carousel-item>
                    <!-- <h1>8 月 1 日 商业Beta版即将发布</h1> -->
                    <img src="../assets/img/banner1.png" />
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
    <div class="index">
        <div class="body">
            <div class="duibi">
                  <div class="global-width">
                      <img src="../assets/img/duibi.jpg">
                
                  </div>
            </div>
            <div class="amazon">
              <div class="global-width">
              <!-- <img src="../assets/img/amazon.png" />  -->
                  <div class="manager">
                      <div class="title">
                          亚马逊店铺管理服务
                      </div>
                      <div class="item">
                          <div class="title">产品管理</div>
                          接入亚马逊产品数据，提供给卖家管理产品信息及针对产品相关信息进行调整修改。
                      </div>
                      <div class="item">
                          <div class="title">订单交易</div>
                          接入亚马逊订单、出库、退款、退货及费用相关数据，并提供卖家分析相关订单利润。
                      </div>
                      <div class="item">
                          <div class="title">广告管理</div>
                          接入亚马逊广告，为卖家提供多维度广告数据分析，并提供更多样的广告定时调价等功能。
                      </div>
                      <div class="item">
                          <div class="title">FBA管理</div>
                          接入亚马逊FBA仓储数据、包括入库计划、库存调整、库龄分析、即时库存等。
                      </div>
                  </div>
                  <div class="service">
                      <div class="title">
                          亚马逊特色服务
                      </div>
                      <div class="item">
                          <div class="title">订单利润核算</div>
                          通过亚马逊订单及费用数据、结合供应链成本数据，对亚马逊订单利润进行精细化核算分析。
                      </div>
                      <div class="item">
                          <div class="title">退货退款率核算</div>
                          通过订单及退货退款数据，对亚马逊相关退款及退货率进行精细化分析核算
                      </div>
                      <div class="item">
                          <div class="title">账单对账核算</div>
                          通过亚马逊账单，对账单内业务进行逐笔和逐金额进行精细化分析核算。
                      </div>
                      <div class="item" style="padding-right: 0">
                          <div class="title">生意数据分析</div>
                          通过亚马逊生意数据，提供给卖家可视化图表分析，帮助卖家快速分析生意数据。
                      </div>
  
                      <div class="item">
                          <div class="title">库存索赔分析</div>
                          帮助卖家获取库存相关明细数据，并分析相关丢失及索赔情况。
                      </div>
                      <div class="item">
                          <div class="title">调入亚马逊仓</div>
                          根据发货亚马逊仓需求，智能建议装箱及全流程系统自动操作管理跟踪功能。
                      </div>
                      <div class="item">
                          <div class="title">账单对账核算</div>
                          结合广告调价策略方法，帮助卖家进行策略管理，及自动进行广告及策略调价管理。
                      </div>
                      <div class="item">
                          <div class="title">广告数据分析</div>
                          帮助卖家，通过多维度自定义进行广告数据分析，帮助卖家进准把控投产比。
                      </div>
                  </div>
              </div>
            </div>
            <div class="power">
                <div class="title">提供三项强大的技术支撑能力，让您更专注于业务方案设计与开发！</div>
                <ul>
                    <li><img src="../assets/img/seting.png"></li>
                    <li><img src="../assets/img/dev.png"></li>
                    <li><img src="../assets/img/big-data.png"></li>
                </ul>
            </div>
            <div class="totest">
                <div class="global-width">
                  <div class="title">开启企业级软件系统新时代</div>
                  <!-- <div class="stitle">开启企业级软件系统新时代</div> -->
                  <a class="to" href="javascript:void(0)" @click="building()">申请首测</a>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
 
  
  export default {
    name: 'Index',
    setup() {
    }
  }
  </script>
  
  <style lang="less" scoped>
      .index {
          .header {
              height: 80px;
              background: #1D254E;
              position: fixed;
              z-index: 100;
              width:100%;
              top:0;
              .global-width1 {
                  margin:0 auto;
              }
              .logo {
                  float: left;
                  img {
                      width: 348px;
                      margin-top:17px;
                      margin-left: 47px;
                  }
              }
              .menu {
                  line-height: 80px;
                  float:right;
                  margin-right:140px;
                  ul {
                      li {
                          font-size: 16px;
                          color: #FFFFFF;
                          margin-right:40px;
                          a {
                              color:#fff;
                              &:hover {
                                  opacity: 0.8;
                              }
                          }
                      }
                  }
              }
              .login {
                  float:right;
                  width:52px;
                  height: 28px;
                  line-height: 28px;
                  background-color: #fff;
                  border-radius: 4px;
                  display: block;
                  text-align: center;
                  margin-top:26px;
                  margin-right:30px;
              }
          }
          .banner {
              margin-top:80px;
              background: #F1F1F1;
              position: relative;
              height: 440px;
              :deep(.el-carousel) {
                  width:100%;
                  margin:0 auto;
              }
              h1 {
                  line-height: 400px;
                  font-size: 45px;
              }
          }
          .body {
              .amazon {
                  .manager {
                          text-align: left;
                      .item {
                          display: inline-block;
                          width: 224px;
                          padding-right: 96px;
                          font-size: 16px;
                          .title {
                              font-size: 20px;
                              color: #338EC7;
                              font-weight: 500;
                              padding-bottom: 15px;
                          }
                      }
                      .item:last-child {
                          padding-right: 0px;
                      }
                      &>.title {
                          font-size: 28px;
                          text-align: center;
                          padding-bottom: 34px;
                      }
                  }
                  .service {
                      text-align: left;
                      margin-top: 150px;
                      margin-bottom: 50px;
                      .item {
                          padding-top: 50px;
                          display: inline-block;
                          width: 224px;
                          padding-right: 96px;
                          font-size: 16px;
                          .title {
                              font-size: 20px;
                              font-weight: 500;
                              padding-bottom: 15px;
                          }
                      }
                      .item:last-child {
                          padding-right: 0px;
                      }
                      &>.title {
                          font-size: 28px;
                          text-align: center;
                      }
                  }
              }
              .duibi {
                  img {
                      margin-top:50px;
                      margin-bottom: 60px;
                  }
              }
              .power {
                  background: #F4FAFF;
                  padding-top:50px;
                  .title {
                      font-size: 32px;
                      padding-bottom:65px;
                  }
                  ul {
                      margin:0 auto;
                      padding-bottom: 120px;
                      li {
                          width:330px;
                          text-align: center;
                          img {
                              
                          }
                      }
                  }
              }
              .totest {
                  background-image: url('../assets/img/bg-1.png');
                  height: 150px;
                  margin:0 auto;
                  text-align: left;
                  .global-width {
                      position: relative;
                  }
                  .title {
                      padding-top:52px;
                      font-size: 32px;
                      color: #fff;
                  }
                  .stitle {
                      padding-top:8px;
                      font-size: 18px;
                      color: #fff;
                  }
                  .to {
                      width:160px;
                      height: 48px;
                      line-height: 48px;
                      color: #338EC7;
                      background-color: #fff;
                      display: block;
                      position: absolute;
                      top: 50px;
                      right: 0px;
                      text-align: center;
                      font-size: 20px;
                  }
              }
          }
          .footer {
              background-color: #383E45;
              color: #fff;
              clear: both;
              .helper {
                  float: left;
                  padding-top: 50px;
                  text-align: left;
                  .quick-menu {
                      float: left;
                      .column {
                          width: 224px;
                          text-align: left;
                          float: left;
                          .title {
                              font-size: 16px;
                              padding-bottom:10px;
                          }
                          ul {
                              li {
                                  display: block;
                                  a {
                                  color: #fff;
                                  opacity: 0.7;
                                      line-height: 28px;
                                      &:hover {
                                          opacity: 0.9;
                                      }
                                  }
                              }
                          }
                      }
                  }
                  .lang {
                      float:left;
                      ul {
                          li {
                              display: block;
                              a {
                                  color: #fff;
                                  opacity: 0.7;
                                  line-height: 28px;
                                  &:hover {
                                      opacity: 0.9;
                                  }
                              }
                          }
                      }
                  }
              }
              .more {
                  padding-top: 30px;
                  padding-bottom: 30px;
                  border-top: 1px solid #aaa;
                  margin-bottom: 50px;
                  color: #fff;
                  opacity: 0.7;
                  a {
                      color: #fff;
                      &:hover {
                          opacity: 0.7;
                      }
                  }
              }
          }
      }
      .clear {
          clear: both;
      }
  </style>