import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Main from '../views/main.vue'
import Details from '../views/details.vue'
import Details1 from '../views/details1.vue'
import Details2 from '../views/details2.vue'
import Details4 from '../views/details4.vue'
import Privacypolicy from '../views/privacypolicy.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect:'/main',
    children:[
      {
        path: '/main',
        name: '首页',
        component: Main
      },
      {
        path: '/details',
        name: '方案',
        component: Details
      },
      {
        path: '/pri',
        name: '开发社区',
        component: Details1
      },
      {
        path: '/sup',
        name: '支持',
        component: Details2
      },
      {
        path: '/buy',
        name: '购买',
        component: Details4
      },
      {
        path: '/privacypolicy',
        name: '隐私条款',
        component: Privacypolicy
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
